import { CLEAR_COOKIE_KEY, enumBonusRuleType, enumBonusStatusPMA } from "./constantsData";
import { get } from "$ACTIONS/TlcRequest";
import { ApiPort } from "$ACTIONS/TLCAPI";
import moment from "moment";
import CryptoJS from "crypto-js";
import { gameRouteMap } from "../data/lib/DataList";
import { Button } from "antd";
import Router from "next/router";
import { CardIcons, fetchBonusDetailWithPlayerBonusId } from "../data/wallet";
import promotionErrorConfigModule from "../components/promotionModalConfigModule";
import { useDispatch, useSelector } from "react-redux";
import { piwikBonus, piwikMessage } from "./piwikUtil";
class Util {
    constructor() {}
    hasClass(elem, cls) {
        cls = cls || "";
        if (cls.replace(/\s/g, "").length == 0) return false;
        return new RegExp(" " + cls + " ").test(" " + elem.className + " ");
    }
    addClass(elem, cls) {
        if (!this.hasClass(elem, cls)) {
            ele.className =
                ele.className == "" ? cls : ele.className + " " + cls;
        }
    }
    removeClass(elem, cls) {
        if (this.hasClass(elem, cls)) {
            let newClass = " " + elem.className.replace(/[\t\r\n]/g, "") + " ";
            while (newClass.indexOf(" " + cls + " ") >= 0) {
                newClass = newClass.replace(" " + cls + " ", " ");
            }
            elem.className = newClass.replace(/^\s+|\s+$/g, "");
        }
    }
    parentsUtil(elem, cls) {
        if (elem) {
            while (elem && !this.hasClass(elem, cls)) {
                elem = elem.parentNode;
            }
            return elem;
        } else {
            return null;
        }
    }
}

/**
 * @description: 手机号码**替代
 * @param {*} number
 * @return {*}
 */
export function numberConversion(number = "") {
    // 如果输入为空，则直接返回空字符串
    if (!number) {
        return "";
    }
    // console.log(number);
    let numberDelPrefix = number.split("-")[1] ? number.split("-")[1] : number.split("-")[0];
    console.log(numberDelPrefix, 'numberDelPrefix')
    let numberArr = numberDelPrefix.split("");
    console.log(numberArr, 'numberArr')
    //Mask & display last 4 characters when length > 4 characters else no mask is required
    if (numberArr && numberArr.length > 4) {
        const tail4 = numberArr.slice(-4);
        return [...Array(numberArr.length - 4).fill("*"), ...tail4].join("");
    } else {
        return numberArr.join("");
    }
}

/**
 * 配合 CXF1-7425 URL 變更需求做出更改
 * 取得game 2nd level 路徑名稱
 * 查找對應的 providerName，providerCode 有重複，因此要判斷 gameCateCode (ex: Sportsbook、ESports...)
 * @param {string} gameCateCode  
 * @param {string} identifier 
 * @returns 
 */
export function getGame2ndLevelRouteName(gameType, identifier) {
    const routeName = gameRouteMap
        .find((cate) => cate.code === gameType)
        ?.routes.find(route=> Object.values(route)[0] === identifier)?.name;
    return routeName || 404;
}

// 移除沙巴体育
export function checkIsRemoveShaba(RegisterDate) {
    const timestamp = new Date(RegisterDate).getTime();
    const HeaderShabaDisplay = document.getElementById("Sportsbook_OWS");
    let times = false;
    if (HeaderShabaDisplay) {
        times = timestamp >= 1606752001000;
        HeaderShabaDisplay.style.display =
            timestamp >= 1606752001000 ? "none" : "inline-block";
    }

    return times;
}

export function getUrlVars() {
    var vars = {},
        hash;
    var hashes = window.location.href
        .slice(window.location.href.indexOf("?") + 1)
        .split("&");
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");
        if (hash[1]) {
            // vars.push(hash[0]);
            vars[hash[0]] = hash[1].split("#")[0];
        }
    }
    return vars;
}

/**
 * 格式化金额
 * 金额有小数点则保留后二位
 * 可选择带是否需要后缀 .00。
 * @param {Number/String} num 金额
 * @param {String} suffixes 是否需要.00后缀
 * @returns 返回格式化后的金额
 */
export function formatAmount(num, needPadEnd=false) {
    if (!num) {
        return 0;
    }
    let numCount = num.toString().split(".");
    let final = numCount[1]
                ? (!needPadEnd
                    || typeof String.prototype.padEnd !== 'function' // caniuse.com/?search=padEnd
                    ? "." + numCount[1].toString().substr(0, 2)
                    : "." + numCount[1].toString().substr(0, 2).padEnd(2,'0'))
                : "";
    const numCountVal =
        (numCount[0] + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,") +
        final;
    return typeof num === "number" && isNaN(num) ? 0 : numCountVal;
    /*
    console.log(formatAmount(100.00, true).toString()==='100');
    console.log(formatAmount(100.10, true).toString()==='100.10');
    console.log(formatAmount(100.14, true).toString()==='100.14');
    console.log(formatAmount(100.1499, true).toString()==='100.14');
    console.log(formatAmount(100.1430, true).toString()==='100.14');
    */
}

export function Cookie(name, value, options) {
    // 如果第二个参数存在
    if (typeof value !== "undefined") {
        options = options || {};
        if (value === null) {
            // 设置失效时间
            options.expires = -1;
        }
        var expires = "";
        // 如果存在事件参数项，并且类型为 number，或者具体的时间，那么分别设置事件
        if (
            options.expires &&
            (typeof options.expires == "number" || options.expires.toUTCString)
        ) {
            var date;
            if (typeof options.expires == "number") {
                date = new Date();
                date.setTime(date.getTime() + options.expires * 60 * 1000);
            } else {
                date = options.expires;
            }
            expires = "; expires=" + date.toUTCString();
        }
        // var path = options.path ? '; path=' + options.path : '', // 设置路径
        var domain = options.domain ? "; domain=" + options.domain : "", // 设置域
            secure = options.secure ? "; secure" : ""; // 设置安全措施，为 true 则直接设置，否则为空

        // 如果第一个参数不存在则清空所有Cookie
        if (name === null) {
            const keys = document.cookie.match(/[^ =;]+(?=\=)/g);
            if (keys) {
                for (let i = keys.length; i--; ) {
                    if (~CLEAR_COOKIE_KEY.indexOf(keys[i])) {
                        document.cookie = [
                            keys[i],
                            "=",
                            encodeURIComponent(value),
                            expires,
                            "; path=/zh",
                            domain,
                            secure,
                        ].join("");
                    }
                }
                for (let i = keys.length; i--; ) {
                    document.cookie = [
                        keys[i],
                        "=",
                        encodeURIComponent(value),
                        expires,
                        "; path=/",
                        domain,
                        secure,
                    ].join("");
                }
            }
        } else {
            // 把所有字符串信息都存入数组，然后调用 join() 方法转换为字符串，并写入 Cookie 信息
            document.cookie = [
                name,
                "=",
                encodeURIComponent(value),
                expires,
                "; path=/",
                domain,
                secure,
            ].join("");
        }
    } else {
        // 如果第二个参数不存在
        var CookieValue = null;
        if (document.cookie && document.cookie != "") {
            var Cookie = document.cookie.split(";");
            for (var i = 0; i < Cookie.length; i++) {
                var CookieIn = (Cookie[i] || "").replace(/^\s*|\s*$/g, "");

                if (CookieIn.substring(0, name.length + 1) == name + "=") {
                    CookieValue = decodeURIComponent(
                        CookieIn.substring(name.length + 1)
                    );
                    break;
                }
            }
        }
        return CookieValue;
    }
}

export function formatSeconds(value) {
    function checkZero(str) {
        str = str.toString();
        return str.length === 1 ? "0" + str : str;
    }

    var seconds = parseInt(value); // 秒
    var minute = 0; // 分
    var hour = 0; // 小时

    if (seconds > 60) {
        minute = parseInt(seconds / 60);
        seconds = parseInt(seconds % 60);
        if (minute > 60) {
            hour = parseInt(minute / 60);
            minute = parseInt(minute % 60);
        }
    }
    var result = "" + checkZero(parseInt(seconds));
    if (minute > 0) {
        result = "" + checkZero(parseInt(minute)) + ":" + result;
    } else {
        result = "00:" + result;
    }
    if (hour > 0) {
        result = "" + checkZero(parseInt(hour)) + ":" + result;
    }
    return result;
}
// 根据秒格式化时间
export function formatDateTime(value) {
    // 前置加零
    const checkZero = (str) => {
        str = str.toString();
        return str <= 9 ? "0" + str : str;
    };
    var seconds = parseInt(value), // 秒
        minute = 0, // 分
        hour = 0, // 小时
        day = 0; // 天

    if (seconds >= 60) {
        minute = parseInt(seconds / 60);
        seconds = parseInt(seconds % 60);
        if (minute >= 60) {
            hour = parseInt(minute / 60);
            minute = parseInt(minute % 60);
            if (hour >= 24) {
                day = parseInt(hour / 24);
                hour = parseInt(hour % 24);
            }
        }
    }
    return [
        checkZero(parseInt(day)),
        checkZero(parseInt(hour)),
        checkZero(parseInt(minute)),
        checkZero(parseInt(seconds)),
    ];
}
// 获取本地格式化时间
export function dateFormat() {
    let date = new Date(Date.now() + 8 * 3600000);
    let str = date.toISOString().replace("T", " ");
    return str.substr(0, str.lastIndexOf("."));
}

//格式化后台接口返回时间，例："2021-01-05T18:17:47.624"
export function formatTime(value) {
    if (value) {
        let time = value.split("T").join(" ").split(".")[0];
        return time;
    }
}
// 浮点数计算
export function mul(a, b) {
    var c = 0,
        d = a.toString(),
        e = b.toString();
    try {
        c += d.split(".")[1].length;
    } catch (f) {}
    try {
        c += e.split(".")[1].length;
    } catch (f) {}
    return (
        (Number(d.replace(".", "")) * Number(e.replace(".", ""))) /
        Math.pow(10, c)
    );
}
function div(a, b) {
    var c,
        d,
        e = 0,
        f = 0;
    try {
        e = a.toString().split(".")[1].length;
    } catch (g) {}
    try {
        f = b.toString().split(".")[1].length;
    } catch (g) {}
    return (
        (c = Number(a.toString().replace(".", ""))),
        (d = Number(b.toString().replace(".", ""))),
        mul(c / d, Math.pow(10, f - e))
    );
}
export function add(a, b) {
    var c, d, e;
    try {
        c = a.toString().split(".")[1].length;
    } catch (f) {
        c = 0;
    }
    try {
        d = b.toString().split(".")[1].length;
    } catch (f) {
        d = 0;
    }
    return (e = Math.pow(10, Math.max(c, d))), (mul(a, e) + mul(b, e)) / e;
}
export function sub(a, b) {
    var c, d, e;
    try {
        c = a.toString().split(".")[1].length;
    } catch (f) {
        c = 0;
    }
    try {
        d = b.toString().split(".")[1].length;
    } catch (f) {
        d = 0;
    }
    return (e = Math.pow(10, Math.max(c, d))), (mul(a, e) - mul(b, e)) / e;
}

export function formatDate(dateStr) {
    const hasPointIdx = dateStr && dateStr.indexOf(".");
    return dateStr
        ? hasPointIdx >= 0
            ? dateStr.replace("T", " ").substring(5, hasPointIdx - 3)
            : dateStr.replace("T", " ").substring(5, dateStr.length - 3)
        : "";
}

export function formatYearMonthDate(dateStr) {
    const hasPointIdx = dateStr && dateStr.indexOf(".");
    const date =  dateStr
        ? hasPointIdx >= 0
            ? dateStr.replace("T", " ").substring(0, hasPointIdx - 3)
            : dateStr.replace("T", " ").substring(0, dateStr.length - 3)
        : "";
    return moment(date).format("DD-MM-YYYY HH:mm")
}

// 延迟加载图片
export function lazyLoadImg(parentNodeId) {
    const imgs = Array.from(
        document.getElementById(parentNodeId).getElementsByTagName("img")
    );
    if (imgs[0] && imgs[0].getAttribute("src")) return;
    imgs.forEach((item) => {
        item && item.setAttribute("src", item.getAttribute("data-src"));
    });
}

/**
 * 无缝滚动
 * @param {string/boolean} target 祖先React节点  [boolean] 是否清除定时器
 * @param {number} [sp=18] 速度
 * @param {string top/right} 移动方位
 * @return 返回 定时器状态
 */
export function marqueeAnimate(target, direction, sp, call) {
    // 清除定时器
    if (typeof target === "boolean" && target === true) {
        clearInterval(timer);
        clearTimeout(onlyTimer);
        return false;
    }

    var timer = null,
        onlyTimer = null;
    var $container = target.childNodes[0],
        container = $container.childNodes[0],
        $marqueeItem =
            container.tagName === "UL"
                ? container.childNodes
                : $container.childNodes,
        last = $marqueeItem[$marqueeItem.length - 1],
        len = $marqueeItem.length,
        speed = sp || 18,
        dir = direction || "top";

    var rolling;
    if (dir == "top") {
        $container.appendChild(container.cloneNode(true));
        // let height = last.offsetTop + last.offsetHeight;

        rolling = function () {
            if (target.scrollTop === last.offsetTop) {
                target.scrollTop = 0;
            } else {
                target.scrollTop++;
            }
            if (target.scrollTop % last.offsetHeight === 0) {
                clearInterval(timer);
                onlyTimer = setTimeout(() => {
                    timer = setInterval(rolling, speed);
                    let index = target.scrollTop / last.offsetHeight + 1;
                    typeof call === "function" &&
                        call(index === len ? 0 : index);
                }, 3000);
            }
        };
    } else if (dir == "right") {
        $container.appendChild(container.cloneNode(true));
        // 此处减去左边的图标显示所占的偏移值
        var width =
            last.offsetLeft + last.offsetWidth - $marqueeItem[0].offsetLeft;
        rolling = function () {
            if (target.scrollLeft == width) {
                target.scrollLeft = 0;
            } else {
                target.scrollLeft++;
            }
        };
    }

    timer = setInterval(rolling, speed); // 设置定时器
    container.addEventListener("mouseenter", function () {
        clearInterval(timer);
        clearTimeout(onlyTimer);
    });
    container.addEventListener("mouseleave", function () {
        onlyTimer = setTimeout(() => {
            // 鼠标移开时重设定时器
            timer = setInterval(rolling, speed);
            let index = target.scrollTop / last.offsetHeight + 1;
            typeof call === "function" && call(index === len ? 0 : index);
        }, 3000);
    });

    return false;
}

/**
 * @description: 邮箱隐藏 ***占位
 * @param {*} email
 * @return {*}
 */
export const mailConversion = (email = "") => {
    // 如果输入为空，则直接返回空字符串
    if (!email) {
        return "";
    }
    //Mask & display last 3 characters before @ when length > 3 characters else no mask is required
    let head = email.split("@")[0];
    let tail = email.split("@")[1];
    let headArr = head.split("");
    if (headArr && headArr.length > 3) {
        const headsTail3 = head.slice(-3);
        return (
            [...Array(head.length - 3).fill("*"), ...headsTail3].join("") +
            "@" +
            tail
        );
    } else {
        return email;
    }
    // const atIndex = email.indexOf('@');

    // // 如果 @ 符号前面的字符数小于等于 4，则将全部的字符替换成 *
    // if (atIndex <= 4) {
    //   return email.replace(/^(.*?@)/, '******@');
    // }

    // // 如果 @ 符号前面的字符数大于 4，则只替换前四个字符
    // return email.replace(/^(.{4}).*?@/, '$1******@');
};

/**
 * @description: 数组对象去重
 * @param {*} obj 数组对象
 * @return {*}
 */
export const deteleObject = (obj) => {
    var uniques = [];
    var stringify = {};
    for (var i = 0; i < obj.length; i++) {
        var keys = Object.keys(obj[i]);
        keys.sort(function (a, b) {
            return Number(a) - Number(b);
        });
        var str = "";
        for (var j = 0; j < keys.length; j++) {
            str += JSON.stringify(keys[j]);
            str += JSON.stringify(obj[i][keys[j]]);
        }
        if (!stringify.hasOwnProperty(str)) {
            uniques.push(obj[i]);
            stringify[str] = true;
        }
    }
    uniques = uniques;
    return uniques;
};

/**
 * @description:指定数组元素相加
 * @param undefined
 * @return {*}
 */
export function SumValue(arr, key) {
    if (Array.isArray(arr)) {
        let arrSum = 0;
        arr.forEach((item, index) => {
            arrSum += item[key];
        });
        return arrSum;
    }
    return 0;
}

/**
 * @description: 获取二级域名 转换动态api 域名
 * @param {*} input 完整域名
 * @return {*}
 */
export function Domainparse(input) {
    if (typeof input !== "string") {
        throw new TypeError("Domain name must be a string.");
    }
    // Force domain to lowercase.
    var domain = input.slice(0).toLowerCase();
    // Handle FQDN.
    // TODO: Simply remove trailing dot?
    if (domain.charAt(domain.length - 1) === ".") {
        domain = domain.slice(0, domain.length - 1);
    }
    var parsed = {
        input: input,
        tld: null,
        sld: null,
        domain: null,
        subdomain: null,
        listed: false,
    };
    var domainParts = domain.split(".");
    // Non-Internet TLD
    if (domainParts[domainParts.length - 1] === "local") {
        return parsed;
    }
    var handlePunycode = function () {
        if (!/xn--/.test(domain)) {
            return parsed;
        }
        if (parsed.domain) {
            parsed.domain = Punycode.toASCII(parsed.domain);
        }
        if (parsed.subdomain) {
            parsed.subdomain = Punycode.toASCII(parsed.subdomain);
        }
        return parsed;
    };
    var rule = null;
    // Unlisted tld.
    if (!rule) {
        if (domainParts.length < 2) {
            return parsed;
        }
        parsed.tld = domainParts.pop();
        parsed.sld = domainParts.pop();
        parsed.domain = [parsed.sld, parsed.tld].join(".");
        if (domainParts.length) {
            parsed.subdomain = domainParts.pop();
        }
        return handlePunycode();
    }
    // At this point we know the public suffix is listed.
    parsed.listed = true;

    var tldParts = rule.suffix.split(".");
    var privateParts = domainParts.slice(
        0,
        domainParts.length - tldParts.length
    );

    if (rule.exception) {
        privateParts.push(tldParts.shift());
    }
    parsed.tld = tldParts.join(".");
    if (!privateParts.length) {
        return handlePunycode();
    }
    if (rule.wildcard) {
        tldParts.unshift(privateParts.pop());
        parsed.tld = tldParts.join(".");
    }

    if (!privateParts.length) {
        return handlePunycode();
    }
    parsed.sld = privateParts.pop();
    parsed.domain = [parsed.sld, parsed.tld].join(".");
    if (privateParts.length) {
        parsed.subdomain = privateParts.join(".");
    }
    return handlePunycode();
}

/**
 * @description: 客服
 * @param {*}
 * @return {*}
 */
export function PopUpLiveChat(listingParams = '') {
    FUN88Live && FUN88Live.close();
    let FUN88Live = window.open(
        "about:blank",
        "chat",
        "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=540, height=650"
    );
    const openServer = (serverUrl) => {
        let serverUrlNew = serverUrl + listingParams
        FUN88Live.document.title = "FUN88 Live Chat";
        FUN88Live.location.href = serverUrlNew;
        FUN88Live.focus();
    };

    let url = localStorage.getItem("serverUrl");
    if (url) {
        openServer(url);
    }
    get(ApiPort.GETLiveChat).then((res) => {
        if (res.isSuccess) {
            localStorage.setItem("serverUrl", res.result);
            !url && openServer(res.result);
        }
    });
}
 /**
     * @description: 生产区间随机数
     * @param {*} min 最低
     * @param {*} max 最大
     * @return {*}
     */
export function  getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

//节流
export const throttle = (func, delay) => {
	let timerId = null;
	let lastArgs = null;
	const throttledFunc = (...args) => {
	  lastArgs = args;
	  if (!timerId) {
		timerId = setTimeout(() => {
		  func(...lastArgs);
		  timerId = null;
		}, delay);
	  }
	};
	return throttledFunc;
};

//返回顶部
export const backToTop =()=> {
	document.querySelector("body")?.scrollIntoView({
		behavior: "smooth", // 平滑滚动
		block: "start"      // 垂直方向上对齐到顶部
	});
}

/**
 * 格式化数字
 * @param {String} value 
 * @returns {Number} 数字类型的结果
 * 例子：123*-45/！@#6￥%7……&*8（9；
 * 返回 123456789
 * 如果是纯字符没数字会返回0
 */
export const numberValidatorHandler = (value)=> {
    if(!value) return "";
    const regex1 = /[^0-9]/g;
    const regex2 = /[+*/e-]/g;
    let validValue = Number(
        String(value).replace(regex1, "").replace(regex2, "")
    );
    return validValue;
};

/**
 * 使用正则表达式匹配两个及以上的空白字符，并替换为一个空格
 * @param {*} str 
 * @returns 
 */
export const replaceMultipleSpacesWithSingle =(str)=> {
    return str.replace(/\s{2,}/g, ' ');
}

// 检查对象是否为空
export const checksObjectHasValue = (obj) => {
    return obj.constructor === Object && Object.keys(obj).length > 0;
};

//加密/解密所用的key
export const access_Code =(key)=>{
    return `for${key}_fun88_m3_desktop`;
}

/**
 * 加密
 * @param {*} text 加密内容
 * @param {String} key  加密所需的key
 * @returns 
 */
export function encrypt(text, key) {
    let md5Key = CryptoJS.MD5(key).toString();
    let keyHex = CryptoJS.enc.Hex.parse(md5Key);
    let encrypted = CryptoJS.TripleDES.encrypt(text, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    let encryptedBase64 = encrypted.toString();
    return encryptedBase64;
}

/**
 * 解密
 * @param {*} text 解密内容
 * @param {String} key 解密所需的key
 * @returns 
 */
export function decrypt(text, key) {
    let md5Key = CryptoJS.MD5(key).toString();
    let keyHex = CryptoJS.enc.Hex.parse(md5Key);
    let decrypt = CryptoJS.TripleDES.decrypt(text, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    let parseData = decrypt.toString(CryptoJS.enc.Utf8);
    return parseData;
}

/**
 * 设置一个到晚上12点过期的Cookie
 * @param {String} eventName 事件名
 */
export const setCookieExpiresForMidnight =(eventName)=> {
    const name = localStorage.getItem("UserName")
    const now = new Date();
    const midnight = new Date(now);
    midnight.setHours(24, 0, 0, 0); // 设置为今天的 24 点，即明天的 0 点
    const minutesUntilMidnight = Math.ceil((midnight - now) / (1000 * 60)); // 计算当前时间到今晚12点的分钟数
    console.log("🚀 ~ blockExpired ~ minutesUntilMidnight:", minutesUntilMidnight)
    Cookie(`${eventName}BlockExpired_${name}`,dateFormat(),minutesUntilMidnight)
}

//逗號分隔，支持小數點，若removeZero為true則將小數點後尾巴的0去除
export function numberformatter(data, precision = 2, removeZeroType = false, commas = true) {
    // 檢查是否為空值、"0" 或非法數字，若是則返回 "0"
    if (!data || data === "0" || isNaN(Number(data))) {
        return "0";
    }

    // 正規表達式處理多餘的位數
    const reg = new RegExp(`(\\.\\d{${precision}})\\d+$`);
    let number = data && data.toString().replace(/[^\d.]/g, '').replace(reg, '$1') || '';

    // 添加千分位逗號
    number = new Decimal(number).toFixed(precision);
    const parts = String(number).split(".");
    if (commas) {
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const formattedNum = precision ? parts.join(".") : parts[0];

    switch(removeZeroType) {
        case "removeWhenDecimalAllZero":
            return formattedNum.replace(/\.0+$/, "");
        case "removeDecimalTailZero":
            return formattedNum.replace(/(\.\d*[1-9])0*$|(\.\d*0)$|^0\.0*$/g, "$1");
        default:
            return formattedNum;
    }
}

/**
 * 將Error Response 統一成單一格式
 * @param {*} res 
 * @returns 
 */
export const responseFormatUnifier = (res) => {
    try {
        const atypicalErrorFormats = {
            atypicalErrorFormat1 : !res?.isSuccess && res?.result && Object.keys(res.result).every(key=> ["code", "message"].find(formatKey=> formatKey === key)),
            atypicalErrorFormat2 : !res?.isSuccess && res?.result && Object.keys(res.result).every(key=> ["errorCode", "message"].find(formatKey=> formatKey === key))
        }
        const fulfillCondition = Object.entries(atypicalErrorFormats).find(condition=>condition[1])?.[0] || null
        if(fulfillCondition){
            let errorCode;
            switch(fulfillCondition){
                case "atypicalErrorFormat1":
                    errorCode = res.result.code;
                    break;
                case "atypicalErrorFormat2":
                    errorCode = res.result.errorCode;
                    break;
                default:
                    break;
            }
            const formattedRes = {
                isSuccess: res.isSuccess,
                errors: [
                    {
                        errorCode: errorCode,
                        description: "",
                        message: res.result.message
                    }
                ]
            }	
            return formattedRes
        }else{
            return res
        }
    } catch (err){
        console.error("responseFormatUnifier error:", err)
        return res
    }
}

// 代替Promise.allSettled
// 舊小米Browser 至少低於v10.9.8-g都不支援
export function allSettled(promises) {
    // map the promises to return custom response.
    const mappedPromises = promises.map((p) =>
        Promise.resolve(p).then(
            (val) => ({ status: "fulfilled", value: val }),
            (err) => ({ status: "rejected", reason: err })
        )
    );

    // run all the promises once with .all
    return Promise.all(mappedPromises);
}

export function getTargetPhoneRules(res, target='84') {
    /*
     {
  "result": [
    {
      "countryName": "Vietnam",
      "countryCode": "86",
      "maxLength": 11,
      "minLength": 11,
      "prefixes": [
        "130",
        "131",
        ...
      ]
    },
    {
      "countryName": "Vietnam",
      "countryCode": "84",
      "maxLength": 11,
      "minLength": 8,
      "prefixes": [
        "999",
        "135"
      ]
    }
  ],
  "isSuccess": true
}
     */
    if (res && Array.isArray(res.result) && target) {
        return res.result.filter(item => item.countryCode==target)
    }
    return [];
}

export function isValidPhone(val, phoneRules) {
    if (!val) {
        return '_EMPTY_VALUE_';
    }
    if (!Array.isArray(phoneRules) || !phoneRules.length) {
        return '_NOT_LOADED_PHONE_RULES_YET_';
    }

    let isHitPrefix = false;
    let ruleOfMinAndMax = [];
    let isMinLengthGood = false;
    let isMaxLengthGood = false;
    for (let rule of phoneRules) {
        for (let prefix of rule.prefixes) {
            if (val.indexOf(prefix)===0) {
                isHitPrefix = true;
                ruleOfMinAndMax = [rule.minLength, rule.maxLength];
                if (val.length >= rule.minLength) {
                    isMinLengthGood = true;
                }
                if (val.length <= rule.maxLength) {
                    isMaxLengthGood = true
                }
            }
        }
    }
    return [isHitPrefix, isMinLengthGood, isMaxLengthGood, ruleOfMinAndMax];
}

export function isSetupSEON() {
    return window.SEONInitialized && window.seon && typeof window.seon.init === 'function' && typeof window.seon.getSession === 'function';
}

export async function getSeonSession() {
    try {
        const config = {
            geolocation: {
                canPrompt: false,
            },
            networkTimeoutMs: 2000,
            fieldTimeoutMs: 2000,
            region: 'eu',
            silentMode: true,
        };
        const seonSession = window?.seon?.getSession ? (await window.seon.getSession(config) || undefined ) : undefined;
        return seonSession;
    } catch (err) {
        console.error("getSeonSession error:", err);
        return undefined;
    }
}


export function utc2offset(date, offset, fmt='YYYY-MM-DD HH:mm:ss') {
    const formattedDate = moment.utc(date).utcOffset(offset).format(fmt);
    const gmtOffset = `GMT${offset >= 0 ? '+' : ''}${offset}`;
    return `${formattedDate} ${gmtOffset}`;
}

export function utc2offsetPMA(date, offset, fmt = 'DD/MM/YYYY HH:mm') {
    if (!date) return "Invalid Date"; // Prevent errors
    const formattedDate = moment.utc(date).utcOffset(offset).format(fmt);
    const gmtOffset = `GMT${offset >= 0 ? '+' : ''}${offset}`;
    return `${formattedDate} ${gmtOffset}`;
}


const Content202 = ({ content = ""}) => {
    return (
        <p
        style={{
            color: "#666666",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: "400",
            margin: "0 0 10px 0"
        }}
        >
            Chúng tôi rất tiếc rằng Đề nghị xem xét <span>[{content}]</span> mà bạn đã đăng ký không thành công. Nếu bạn có bất kỳ câu hỏi nào, vui lòng <span
            style={{
                color: "#00A6FF",
            }}
            onClick={() => PopUpLiveChat()}
            >liên hệ với CSKH</span>.
        </p>
    )
}

const PMA201 = ({ content201 = ""}) => {
    console.log("🚀 ~ PMA201 ~ content201", content201)
    const styles = {
        card: {
            border: "1px solid #E3E3E3",
            borderRadius: "8px",
            padding: "16px",
            width: "380px",
            fontFamily: "Arial, sans-serif",
        },
        header: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "12px",
            gap: "8px",
        },
        details: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
        },
    };
    return (
        <div style={styles.card}>
            <div style={styles.header}>
                <h3>{content201?.campaignNativeTitle}</h3>
                <div>
                    {
                        <CardIcons
                            productGroups={{
                                productGroup:
                                    content201?.walletProductGroupCode,
                            }}
                        />
                    }
                </div>
            </div>
            <div>
                <div style={styles.details}>
                    <div
                        style={{
                            color: "#666666",
                            fontWeight: "400",
                            fontSize: "12px",
                        }}
                    >
                        Thưởng trả trước
                    </div>
                    <div
                        style={{
                            color: "#222222",
                            fontSize: "18px",
                            fontWeight: "600",
                            lineHeight: "21px",
                        }}
                    >
                        <span>{content201?.bonusGivenAmount + " đ"}</span>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            color: "#666666",
                            fontSize: "12px",
                            lineHeight: "16px",
                            fontWeight: "400",
                        }}
                    >
                        <span>Thời gian kết thúc Khuyến mãi</span>
                    </div>
                    <div
                        style={{
                            color: "#666666",
                            fontSize: "12px",
                            lineHeight: "16px",
                            fontWeight: "400",
                            width: "max-content",
                        }}
                    >
                        {utc2offsetPMA(
                            content201?.expiredDate,
                            8
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

const BonusCard = ({details = {}, content201 = null, otherInfo = {}, appliedHistory }) => {
    let isPreBonus = appliedHistory?.bonusRuleType === enumBonusRuleType.PRE;
    let isPostBonus = appliedHistory?.bonusRuleType === enumBonusRuleType.POST;
    let cateId = Number(details?.memberNotificationCategoryId);
    console.log("🚀 ~ BonusCard ~ details", details, appliedHistory)
    if(content201){
        details.bonusTitle = content201.campaignNativeTitle;
        details.bonusAmount = content201.bonusGivenAmount;
        details.expiryDate = content201.expiredDate;

    }   
    const styles = {
        card: {
            border: "1px solid #E3E3E3",
            borderRadius: "8px",
            padding: "16px",
            width: "380px",
            fontFamily: "Arial, sans-serif",
        },
        header: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "12px",
        },
        details: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
        },
    };
    return (
        <div>
            {isPreBonus ? (
                <div style={styles.card}>
                    <div style={styles.header}>
                        <h3>
                            {appliedHistory?.bonusName || details?.bonusTitle}
                        </h3>
                        <div>
                            {<CardIcons productGroups={appliedHistory} />}
                        </div>
                    </div>
                    <div>
                        <div style={styles.details}>
                            <div
                                style={{
                                    color: "#666666",
                                }}
                            >
                                Số tiền thưởng
                            </div>
                            <div
                                style={{
                                    color: "#222222",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    lineHeight: "21px",
                                }}
                            >
                                <span>
                                    {appliedHistory?.bonusAmount ||
                                        details?.bonusAmount} đ
                                </span>
                            </div>
                        </div>
                        <div style={styles.details}>
                            <div
                                style={{
                                    color: "#666666",
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    fontWeight: "400",
                                    width: "60%"
                                }}
                            >
                                <span>Thời gian kết thúc:</span>
                                {utc2offsetPMA(
                                    appliedHistory?.expiredDate,
                                    8
                                )}
                            </div>
                            {enumBonusStatusPMA.displayStatusText(
                                cateId,
                                appliedHistory?.status,
                                isPreBonus
                            )}
                        </div>
                    </div>
                    {appliedHistory?.statusTipsMessage && (
                        <div>
                            <hr
                                style={{
                                    margin: "10px 0",
                                    border: "none",
                                    borderBottom: "1px solid #E3E3E3",
                                }}
                            />
                            <div
                                style={{
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    fontWeight: "400",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <span>
                                    <img
                                        style={{
                                            marginRight: "5px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        src="/vn/img/1wallet/00/grey-info-icon.svg"
                                    />
                                </span>
                                <span style={{
                                    color:'#666'
                                }}>
                                {appliedHistory?.statusTipsMessage}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div style={styles.card}>
                    <div style={styles.header}>
                        <h3>
                            {appliedHistory?.bonusName || details?.bonusTitle}
                        </h3>
                        <div>
                            {<CardIcons productGroups={appliedHistory} />}
                        </div>
                    </div>
                    <div>
                        <div style={styles.details}>
                            <div
                                style={{
                                    color: "#666666",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                }}
                            >
                                Thưởng có sẵn
                            </div>
                            <div
                                style={{
                                    color: "#222222",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    lineHeight: "21px",
                                }}
                            >
                                <span>
                                    {appliedHistory?.bonusAmount ||
                                        details?.bonusAmount}
                                </span>{" "}
                                đ
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    color: "#999999",
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    fontWeight: "400",
                                    width: "60%"
                                }}
                            >
                                <span>Kết thúc:</span>
                                {utc2offsetPMA(
                                    appliedHistory?.expiredDate,
                                    8
                                )}
                            </div>
                            {enumBonusStatusPMA.displayStatusText(
                                cateId,
                                appliedHistory?.status,
                                isPreBonus
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
const demoContent = "{campaignNativeTitle};{campaignId};{bonusGivenAmount};{expiredDate};{walletProductGroupCode};";
function handle201MessageContent(content = demoContent) {

    // Keys to map
    const keys = [
    "campaignNativeTitle",
    "campaignId",
    "bonusGivenAmount",
    "expiredDate",
    "walletProductGroupCode"
    ];

    const values = content?.split(";")?.filter(Boolean);

    let mappedContent = keys.reduce((acc, key, index) => {
        acc[key] = values[index] ? values[index].replace(/{|}/g, "") : "";
        return acc;
        }, {}
    );
    return mappedContent;
}

export async function SortingBonusCardFromPMA(details, callbacks, otherInfo = {}) {
    let memberNotificationCategoryId;
    let content201 ,content202;
    console.log("🚀 ~ SortingBonusCardFromPMA ~ callbacks", details)
    let appliedHistoryWithId = {};
    if(details?.playerBonusId){
        let resp = await fetchBonusDetailWithPlayerBonusId(details?.playerBonusId)
        console.log("🚀 ~ SortingBonusCardFromPMAfetchBonusDetailWithPlayerBonusId ~ resp", resp)
        if(resp?.isSuccess && resp?.result?.length > 0){
            console.log("🚀 ~ SortingBonusCardFromPMAfetchBonusDetailWithPlayerBonusId ~ resp", resp)
            appliedHistoryWithId = resp?.result[0];
            piwikMessage.run5(resp?.result[0]?.status || '---');
            console.log("🚀 ~ SortingBonusCardFromPMA ~ appliedHistoryWithId", appliedHistoryWithId)
        }
    }
    const onClickFn = () => {
        Pushgtagdata(
            "Message",
            "View Bonus Detail",
            "Message_C_BonusDetail",
            false,
            [
                {
                    customVariableKey: "Message_C_BonusDetailStatus",
                    customVariableValue: details?.memberNotificationCategoryId,
                },
            ]
        );

        console.log("🚀 ~ SortingBonusCardFromPMA ~ callbacks", callbacks)
    
        if (isPreBonus) {
            callbacks.setFirstTab("2");
            callbacks.setSecondTab("4");
        } else if (isPostBonus) {
            const categoryId = Number(details?.memberNotificationCategoryId); // Ensure it's a number
            console.log("🚀 ~ SortingBonusCardFromPMA ~ categoryId", categoryId);
    
            if (categoryId === 5) {
                console.log("🚀 ~ SortingBonusCardFromPMA5 ~ categoryId", categoryId);
                callbacks.setFirstTab("2");
                callbacks.setSecondTab("3");
            } else if ([6, 7, 8, 999].includes(categoryId)) {
                callbacks.setFirstTab("2");
                callbacks.setSecondTab("4");
    
                if ([7, 8].includes(categoryId) && otherInfo?.toggleOnOff) {
                    callbacks?.openBalanceModal();
                    return; // Exit early to prevent page navigation
                }
            } else {
                callbacks.setFirstTab("2");
                callbacks.setSecondTab("4");
            }
        }
    
        Router.push("/promotions"); // Only one call to push, reducing redundancy
    };
    
    
    let isPreBonus = appliedHistoryWithId?.bonusRuleType === enumBonusRuleType.PRE;
    let isPostBonus = appliedHistoryWithId?.bonusRuleType === enumBonusRuleType.POST;
    memberNotificationCategoryId = details.memberNotificationCategoryId;
    if(memberNotificationCategoryId === 201) {
        content201 = handle201MessageContent(details?.content);
    }
    let is_201_202 = [201, 202].includes(details?.memberNotificationCategoryId);
    let shouldUseOldPMA = !is_201_202 && !details?.playerBonusId;
    if(shouldUseOldPMA){
        memberNotificationCategoryId = -99;
    }
    console.log("🚀 ~ SortingBonusCardFromPMA ~ memberNotificationCategoryId", memberNotificationCategoryId)
    switch (memberNotificationCategoryId) {
        case 5:
        case 6:
        case 7:
        case 8:
        case 999: // bonous status completed
            return (
                <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    position: "relative",
                    zIndex: "100"
                }}
                >
                <div>
                <BonusCard
                appliedHistory={appliedHistoryWithId}
                details={details}
                otherInfo={otherInfo}
                />
                {isPostBonus && (details?.bonusStatusId === enumBonusStatusPMA.Complete.ID) && <div style={{color:"#666666", fontSize:"14px", lineHeight:"20px", fontWeight:"400", marginTop:"15px", marginBottom:"25px"}}>Để rút số tiền thưởng, vui lòng hoàn thành yêu cầu doanh thu, nếu không, thưởng có thể <br/> bị thu hồi.</div>}
                </div>
                <Button
                onClick={onClickFn}
                style={{
                    border: "1px solid #00A6FF",
                    color: "#00A6FF",
                    position: "absolute",
                    right: "-40px"
                    // padding: "8px 16px",
                }}>Xem ngay</Button>
                </div>
            );
        case 201: // bonous status waiting to start
            return (
                <div>
                <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    position: "relative",
                    zIndex: "100",
                    marginTop: "8px",
                }}
                >
                <PMA201
                content201={content201}
                otherInfo={otherInfo}
                />
                <Button
                onClick={() => {
                    Pushgtagdata(
                        "Message",
                        "View Bonus Detail",
                        "Message_C_BonusDetail",
                        false,
                        [
                            {
                                customVariableKey: "Message_C_BonusDetailStatus",
                                customVariableValue: details?.memberNotificationCategoryId,
                            },
                        ]
                    );
                    callbacks.setFirstTab("2")
                    callbacks.setSecondTab("1")
                    Router.push("/promotions");
                }}
                style={{
                    border: "1px solid #00A6FF",
                    color: "#00A6FF",
                    position: "absolute",
                    right: "-40px"
                    // padding: "8px 16px",
                }}>Xem ngay</Button>
                </div>
                <div>
                    <p
                    style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#666666",
                        lineHeight: "16px",
                        width: "92%",
                        marginBottom: "11px",
                        marginTop: "12px"
                    }}
                    >
                    Vui lòng chọn "Nhận khuyến mãi" để tham gia trước "Thời gian kết thúc khuyến mãi"; nếu không, Khuyến mãi sẽ không còn hiệu lực, và sẽ bị xóa khỏi danh sách.
                    </p>
                </div>
                </div>
            )
        case 202: // bonuse failed
            return (
                <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    position: "relative",
                    zIndex: "100",
                    gap: "8px"
                }}
                >
                <span
                style={{
                    width: "75%",
                }}
                >
                <Content202 content={details?.content}/>
                </span>
                <Button
                onClick={() => {
                    Pushgtagdata(
                        "Message",
                        "View Bonus Detail",
                        "Message_C_BonusDetail",
                        false,
                        [
                            {
                                customVariableKey: "Message_C_BonusDetailStatus",
                                customVariableValue: details?.memberNotificationCategoryId,
                            },
                        ]
                    );
                    Router.push("/promotions");
                }}
                style={{
                    border: "1px solid #00A6FF",
                    color: "#00A6FF",
                    position: "absolute",
                    right: "-40px"
                    // padding: "8px 16px",
                }}>Xem các ưu đãi khácy</Button>
                </div>
            )
        case -99: 
        return <div>{details?.content}</div>
        default:
            return <p>No additional details available for this notification.</p>;        
    }
}


export async function fetchAndRenderBonusInboxPMA(details) {
    let memberNotificationCategoryId = details.memberNotificationCategoryId;
    try {
        // const useDummyData = true; // Toggle this to false in production

        // let data;

        // if (useDummyData) {
        //     data = dummyResponses[memberNotificationCategoryId];
        //     if (!data) {
        //         return <p>No dummy data available for this notification.</p>;
        //     }
        // } else {
        //     // Replace with your actual API call
        //     const response = await fetch(`https://example.com/api/${memberNotificationCategoryId}`);
        //     if (!response.ok) throw new Error("API call failed: " + response.statusText);
        //     data = await response.json();
        // }

        switch (memberNotificationCategoryId) {
            case 6:
                return (
                    <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                    }}
                    >
                    <BonusCard
                    date={data.date}
                    message={data.message}
                    />
                    <Button style={{
                        border: "1px solid #00A6FF",
                        color: "#00A6FF",
                        // padding: "8px 16px",
                    }}>Xem ngay</Button>
                    </div>
                );
            case "promotion":
                return (
                    <div className="promotion">
                        <h4>{data.title}</h4>
                        <ul>
                            {data.items.map((item, index) => (
                                <li key={index}>
                                    {item.name} - {item.value}
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            case "personalNotification":
                return (
                    <div className="personal-notification">
                        <h4>{data.message}</h4>
                    </div>
                );
            case "accountUpdate":
                return (
                    <div className="account-update">
                        <h4>{data.message}</h4>
                    </div>
                );
            case "systemAlert":
                return (
                    <div className="system-alert">
                        <h4>System Alert</h4>
                        <p>{data.alertMessage}</p>
                    </div>
                );
            case "promotionRewards":
                return (
                    <div className="promotion-rewards">
                        <h4>{data.message}</h4>
                        <p>{data.details}</p>
                    </div>
                );
            case "maintenanceAlert":
                return (
                    <div className="maintenance-alert">
                        <h4>Maintenance Alert</h4>
                        <p>{data.alertMessage}</p>
                    </div>
                );
            case "featureUpdate":
                return (
                    <div className="feature-update">
                        <h4>Feature Update</h4>
                        <p>{data.alertMessage}</p>
                        <p>{data.details}</p>
                    </div>
                );
            default:
                return <p>No additional details available for this notification.</p>;
        }
        
    } catch (error) {
        console.error("Error fetching notification data:", error);
        return <p>Error loading additional data.</p>;
    }
}

/**
 * To normalize BFFSC Response for success(not completed bcz most of success response doesn't contain `code`) and error.
 *
 */
export function normalizeBffscResponse(res, SUCCESS_CODE='') {
    let hasKey = (o, k) => Object.prototype.hasOwnProperty.call(o, k);
    const isAlikePOSTCampaignEnrollmentsByActionType1Error = res => res && res.isSuccess===false
        && res.result && hasKey(res.result, 'errorCode') && hasKey(res.result, 'message') // && hasKey(res.result, 'errorDesc');
    const isAlikeGETBonusApplicationsEligibleError = res => res?.result?.errorCode
    const isAlikePOSTBonusApplications /*v2.0*/ = res => typeof res?.result?.message==='string' && res.result.message.includes('申请红利失败') || res?.result?.bonusResult;
    const isAlikePOSTCampaignApplicationsCP30011 = res => res?.ErrorCode && res?.Message;
    const DEFAULT = '';
    let code = DEFAULT, msg = DEFAULT, desc = DEFAULT;
    const IS_SUCCESS_TYPE = res => res && res.isSuccess && res.result && res.result.code === SUCCESS_CODE;
        // ^^^^^^^^^^^^^ design this variable for POST/api/Member/Register, NOT for general BFFSC Success Response
        //                                        PATCH/api/Member
    const IS_ERR_TYPE_1 = res => res && res.result && hasKey(res.result, 'code');
        // ^^^^^^^^^^^^ for which API ? --> PUT/api/Member when 500
    const IS_ERR_TYPE_2 = res => res && Array.isArray(res.errors) && res.errors.length;
    if (IS_SUCCESS_TYPE(res)) {
        // design for POST/api/Member/Register, NOT for general BFFSC Success Response
        //            PATCH/api/Member
        code = SUCCESS_CODE;
        msg = res.message || res.result.message;
    } else if (IS_ERR_TYPE_1(res)) {
        code = res.result.code;
        msg = res.result.message;
    } else if (IS_ERR_TYPE_2(res)) {
        let o = res.errors[0];
        code = o.errorCode;
        msg = o.message;
        desc = o.description;
    } else if (isAlikePOSTCampaignApplicationsCP30011(res)) {
        code = res.ErrorCode;
        msg = res.Message;
    } else if (isAlikeGETBonusApplicationsEligibleError(res)) {
        code = res.result.errorCode;
        msg = res.result?.message; // for example, for isAlikePOSTBonusApplications(res)
    } else if (isAlikePOSTCampaignEnrollmentsByActionType1Error(res)) {
        code = res.result.errorCode;
        msg = res.result.message;
        desc = res.result.errorDesc;
    } else if (isAlikePOSTBonusApplications(res)) {
        code = res.result.errorCode
        if (!code && Array.isArray(res.errors) && res.errors.length) {
            code = res.errors[0]
        }
        msg = res.result.message
        desc = res.result?.bonusResult?.message;
    }
    return [code||DEFAULT, msg||DEFAULT, desc||DEFAULT];
}
normalizeBffscResponse.isEmpty = ret => !ret.join('') // see also `DEFAULT` to figure out isEmpty logic.



export function convertDateFormat(dateString) {
    if (!dateString) {
        return "Invalid Date";
    }
    
    // Extract the first valid date part (YYYY-MM-DDTHH:mm:ss)
    const validDatePart = dateString.slice(0, 19);

    // Parse the date into a Date object
    const date = new Date(validDatePart);

    // Check if the date is valid
    if (isNaN(date)) {
        return "Invalid Date";
    }

    // Format date manually to match `dd/mm/yyyy HH:mm GMT+8`
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes} GMT+8`;
}

const enumWalletProductGroupId = {
    MAIN: 1,
    SB: 2,
    LD: 4,
    P2P: 24,
    SLOT: 32,
    KENO: 64,
}

export const convertToV1Result = (v2res) => {
    // Extract relevant data from the v2 response
    const v2 = v2res?.result || {};
    const balances = v2.balances || [];

    // Utility function to find a balance by walletProductGroupId
    const findBalance = (groupId) => balances.find(v => v.walletProductGroupId === groupId)?.balance;

    // Return the transformed data
    return [
        {
            balance: v2.totalBalance,
            state: "Available",
            walletProductGroupCode: "TotalBal",
            localizedName: "Tổng Số Dư", // Total Balance
            category: "TotalBal",
            name: "TotalBal",
            walletProductGroupId: enumWalletProductGroupId.MAIN,
        },
        {
            balance: findBalance(enumWalletProductGroupId.MAIN),
            state: "Available",
            walletProductGroupCode: "MAIN",
            name: "MAIN",
            localizedName: "Tổng Số Dư", // Total Balance
            category: "Main",
            walletProductGroupId: enumWalletProductGroupId.MAIN,
        },
        {
            balance: findBalance(enumWalletProductGroupId.SB),
            state: "Available",
            walletProductGroupCode: "SB",
            name: "SB",
            localizedName: "Thể Thao / Esports", // Sports / eSports
            category: "Sportsbook",
            walletProductGroupId: enumWalletProductGroupId.SB,
        },
        {
            balance: findBalance(enumWalletProductGroupId.LD),
            state: "Available",
            walletProductGroupCode: "LD",
            name: "LD",
            walletProductGroupName: "Casino", // Live Casino
            category: "LiveDealer",
            walletProductGroupId: enumWalletProductGroupId.LD,
        },
        {
            balance: findBalance(enumWalletProductGroupId.P2P),
            state: "Available",
            walletProductGroupCode: "P2P",
            name: "P2P",
            walletProductGroupName: "3D Casino / Game Tốc Độ / Bắn Cá",
            category: "P2P",
            walletProductGroupId: enumWalletProductGroupId.P2P,
        },
        {
            balance: findBalance(enumWalletProductGroupId.SLOT),
            state: "Available",
            walletProductGroupCode: "SLOT",
            localizedName: "Trò chơi", // Slots / Fishing
            name: "SLOT",
            category: "Slots",
            walletProductGroupId: enumWalletProductGroupId.SLOT,
        },
        {
            balance: findBalance(enumWalletProductGroupId.KENO),
            state: "Available",
            walletProductGroupCode: "KENO",
            name: "KENO",
            walletProductGroupName: "Xổ Số", // Lottery
            category: "Keno",
            walletProductGroupId: enumWalletProductGroupId.KENO,
        },
    ];
};

const WalletGroupMokcingResult = [
    {
        "walletProductGroupId": 1,
        "walletProductGroupName": "Tổng Số Dư",
        "walletProductGroupCode": "MAIN"
    },
    {
        "walletProductGroupId": 2,
        "walletProductGroupName": "Thể Thao / Esports ",
        "walletProductGroupCode": "SB"
    },
    {
        "walletProductGroupId": 4,
        "walletProductGroupName": "Casino",
        "walletProductGroupCode": "LD"
    },
    {
        "walletProductGroupId": 24,
        "walletProductGroupName": "3D Casino / Game Tốc Độ / Bắn Cá",
        "walletProductGroupCode": "P2P"
    },
    {
        "walletProductGroupId": 32,
        "walletProductGroupName": "Trò chơi",
        "walletProductGroupCode": "SLOT"
    },
    {
        "walletProductGroupId": 64,
        "walletProductGroupName": "Xổ Số",
        "walletProductGroupCode": "KENO"
    }
];

export function getWalletProductGroupsIconFileName(item) {
    if (!item || typeof item !== 'object') return null;
    const { walletProductGroupId = null } = item;
    if (!walletProductGroupId) return null;
    return `walletProductGroupId${walletProductGroupId}.svg`;
}

export function createFinder(arrayOfObjecst, key) {
    if(!Array.isArray(arrayOfObjecst) || !arrayOfObjecst.length || !key) return null;
    return (target) => arrayOfObjecst.find(obj => obj[key] === target);
}

export function getWalletProductGroupsIcon(GameGroupCode) {
    return createFinder(WalletGroupMokcingResult, 'walletProductGroupCode')(GameGroupCode);
}

export function formatToCustomTimezone(dateString, offsetHours = 8) {
    const date = new Date(dateString);

    // Adjust the date to the desired timezone (GMT+8 in this case)
    const localTime = new Date(date.getTime() + offsetHours * 60 * 60 * 1000);

    // Format date components
    const day = String(localTime.getUTCDate()).padStart(2, '0');
    const month = String(localTime.getUTCMonth() + 1).padStart(2, '0');
    const year = localTime.getUTCFullYear();
    const hours = String(localTime.getUTCHours()).padStart(2, '0');
    const minutes = String(localTime.getUTCMinutes()).padStart(2, '0');

    // Build the final string
    return `${day}/${month}/${year} ${hours}:${minutes} GMT+${offsetHours}`;
}


export const getCurrentTurnoverAndRequiredTurnover = (item) => {
    let currentTurnover, requiredTurnover;
    if (typeof item?.progress==='string' && item?.progress?.includes('/')) {
        [currentTurnover, requiredTurnover] = item.progress.split('/');
    }
    return [currentTurnover, requiredTurnover];
  }

export const handleCancelSignUpBonusError = (res) => {
    let errorCode = res?.result?.errorCode || "";
    let config = null;
    console.log("handleCancelSignUpBonusError",res)
    switch(errorCode){
        case"CP30013":
        case"BP00120":
        config = promotionErrorConfigModule.ClaimableCliclToCacncelCP30013;
        config = {
            ...config,
            content: ({handleClose}, props) => {
                return <div style={{ paddingTop: "44px" }}>
                    <h3 style={{ textAlign: "center" }}>Hủy bỏ không thành công</h3>
                    <p
                        style={{
                            textAlign: "center",
                            marginBottom: "32px",
                            color: "#666666",
                        }}
                    >
                        {res?.result?.data?.productVendors?.length > 1 ? res?.result?.data?.productGroupName : res?.result?.data?.productVendors[0]} có cược chưa thanh toán, vui lòng thử lại sau.
                    </p>
                    <Button
                            style={{
                                backgroundColor:"#00A6FF",
                                marginTop: "12px",
                                color: "#FFFFFF",
                                height: "32px",
                            }}
                            onClick={() => {
                                handleClose();
                            }}
                            >
                            Đã Hiểu
                    </Button>
                </div>
            }
        }
         return {
            showModal: true,
            config: config
         }

         default:
            return {
                showModal: false,
            }


    }
}

export default Util;
