import "url-search-params-polyfill"; //用于兼容URLSearchParams
import { ApiPort, API8SETS } from "$ACTIONS/TLCAPI";
import HostConfig from "./Host.config";
import { get } from "$ACTIONS/TlcRequest";
import { Modal, Icon } from "antd";
import { SportsbookGameCatCode, ESportsGameCatCode, InstantGamesGameCatCode, LiveCasinoGameCatCode, P2PGameCatCode, SlotGameCatCode, KenoLotteryGameCatCode } from "$ACTIONS/constantsData";
import { translate } from "$ACTIONS/Translate";
import Qs from "qs";
import { responseFormatUnifier } from "./util";
import moment from "moment";
import { Slide, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* 目前会有两个地方用到，都很重要! 所有涉及到注册的会有，涉及到下载APP，客户端，中心会有 */
/* 仅仅是用在注册而已 */
export const checkAffQueryString = () => {
    if (window.location.search) {
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("aff"))
            Cookie.Create(
                "CO_affiliate",
                "affiliate=" + urlParams.get("aff"),
                2
            );
        if (urlParams.has("media"))
            Cookie.Create("CO_Media", "Media=" + urlParams.get("media"), 2);
        if (urlParams.has("web"))
            Cookie.Create(
                "CO_WebStieID",
                "WebStieID=" + urlParams.get("web"),
                2
            );
        if (urlParams.has("referrer"))
            Cookie.Create(
                "CO_Referer",
                "Referer=" + urlParams.get("referrer"),
                2
            );
    }
};

/* 获取url的指定参数值  */
export function getQueryVariable(call) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == call) {
            return pair[1];
        }
    }
    return false;
}


/*目前逻辑会先获取url中的代理没有则获取api域名返回的代理code */
let GetDomainOnce = false;
export const getAffiliateReferralCode = () => {
    let affiliaUrl = localStorage.getItem("affiliateUrlLM");
    if (affiliaUrl != null) {
        global.affiliateUrlLM = affiliaUrl;
    }
    /* 先获取域名里面的代理code,没有再去检查api返回的 */
    if (getQueryVariable("aff")) {
        Cookie.Create(
            "CO_affiliate",
            "affiliate=" + getQueryVariable("aff"),
            2
        );
        return getQueryVariable("aff");
    } else if (
        Cookie.GetCookieKeyValue("CO_affiliate") != "undefined" &&
        Cookie.GetCookieKeyValue("CO_affiliate") != ""
    ) {
        console.log(Cookie.GetCookieKeyValue("CO_affiliate"));
        return Cookie.GetCookieKeyValue("CO_affiliate");
    } else {
        !GetDomainOnce &&
            get(ApiPort.GETDomainUrl + ApiPort.LOCAL_HOST + API8SETS, ApiPort.LOCAL_HOST).then((res) => {
                if (res && res.isSuccess && res.result) {
                    global.affiliateUrlLM = res.result.url;
                    localStorage.setItem("affiliateUrlLM", res.result.url);
                    if (res.result.affiliateCode != "") {
                        Cookie.Create(
                            "CO_affiliate",
                            "affiliate=" + res.result.affiliateCode,
                            2
                        );
                        return res.result.affiliateCode;
                    }
                }
            });
        GetDomainOnce = true;
    }

    return "";
};

/*目前逻辑会先获取url中的代理没有则获取api域名返回的代理code */
let AsycGetDomainOnce = false;
export const asycGetAffiliateReferralCode = async () => {
    let affiliaUrl = localStorage.getItem("affiliateUrlLM");
    if (affiliaUrl != null) {
        global.affiliateUrlLM = affiliaUrl;
    }

    // Check if the affiliate code is present in the URL
    if (getQueryVariable("aff")) {
        const affCode = getQueryVariable("aff");
        Cookie.Create("CO_affiliate", `affiliate=${affCode}`, 2);
        return affCode;
    }

    // Check if the affiliate code is present in the cookies
    const cookieAffiliate = Cookie.GetCookieKeyValue("CO_affiliate");
    if (cookieAffiliate && cookieAffiliate !== "undefined") {
        console.log(cookieAffiliate);
        return cookieAffiliate;
    }

    // Fallback: Fetch from API if not found in URL or cookies
    if (!AsycGetDomainOnce) {
        try {
            const res = await get(ApiPort.GETDomainUrl + ApiPort.LOCAL_HOST + API8SETS, ApiPort.LOCAL_HOST);
            if (res && res.isSuccess && res.result) {
                global.affiliateUrlLM = res.result.url;
                localStorage.setItem("affiliateUrlLM", res.result.url);
                if (res.result.affiliateCode) {
                    Cookie.Create(
                        "CO_affiliate",
                        `affiliate=${res.result.affiliateCode}`,
                        2
                    );
                    return res.result.affiliateCode;
                }
            }
        } catch (error) {
            console.error("Error fetching domain URL:", error);
        }
        AsycGetDomainOnce = true;
    }

    return "";
};


export const Cookie = {
    Create: (name, value, days) => {
        let expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }
        let domain = document.location.hostname;
        let domainSplits = domain.split(".");
        let isIPDomain =
            /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(
                domain
            );
        if (!(domainSplits.length === 1) && !isIPDomain) {
            if (domainSplits.length >= 3) {
                domainSplits = domainSplits.slice(-2);
                domain = "." + domainSplits.join(".");
            } else {
                domain = "." + domainSplits.join(".");
            }
        }
        var encodeValue = encodeURIComponent(value);
        document.cookie =
            name + "=" + encodeValue + expires + "; path=/; domain=" + domain;
        // document.cookie = name + '=' + value + expires + '; path=/;';
    },
    Delete: (cookieName) => {
        Cookie.Create(cookieName, "", -1);
        document.cookie =
            cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    },
    Get: (cookieName) => {
        var name = cookieName + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    GetCookieKeyValue: (cookieName) => {
        let aff = Cookie.Get(cookieName);
        return aff ? aff.split("=")[1] : "";
    },
};

export function isGuest() {
    return typeof localStorage !=='undefined' && !localStorage.getItem("access_token")
};

/**
 * 未登录可以打开的游戏
 * 目前体育和电竞所有的游戏都可以未登录打开
 * @param {*} gameCatCode 
 * @param {*} provider 
 * @returns 
 */
export function isThisAllowGuestOpenGame(gameCatCode, provider) {
    const SPORTS_BOOK_GAME = ["OWS","CML","SBT","VTG"];
    const ESPORTS_GAME = ["IPES","TFG"];
    provider = provider && typeof provider === 'string' ? provider.toUpperCase() : undefined;

    return (
        (gameCatCode === SportsbookGameCatCode && (SPORTS_BOOK_GAME.some((item)=>item === provider))) ||
        (gameCatCode === ESportsGameCatCode && (ESPORTS_GAME.some((item)=>item === provider)))
    )
}

export function NotAllowedOpenGameWithQueryParams({ productType }) {
    const NotAllowedProductType = ["Sportsbook", "ESports"]
    return NotAllowedProductType.some((item) => item.toLocaleLowerCase() == productType?.toLocaleLowerCase());
}

/**
 * 未登录可以查看列表的游戏
 * @param {*} _gameCatCode 
 * @param {*} _provider
 * @returns 
 */
export function isThisAllowGuestOpenGCategory(_gameCatCode, _provider) {
    _provider = _provider && typeof _provider==='string' ? _provider.toUpperCase() : undefined;
    return (_gameCatCode===InstantGamesGameCatCode && _provider === 'SPR')
        || (_gameCatCode===InstantGamesGameCatCode && _provider === 'GLX')
        || (_gameCatCode===SportsbookGameCatCode && _provider === 'VTG')
        || _gameCatCode===LiveCasinoGameCatCode
        || _gameCatCode===P2PGameCatCode
        || _gameCatCode===SlotGameCatCode
        || _gameCatCode===KenoLotteryGameCatCode
        ;
}

/* 對敏感文字做遮擋 此為使用者名稱*/
export function obfuscateSensitiveWords(str) {
    let hasSpace = /\s/.test(str);

    function maskName(str) {
        let result = '';

        for (let i = 0; i < str.length; i++) {
            if (i === 0 || str[i - 1] === ' ') {
                result += str[i];
            } else if (str[i] === ' ') {
                result += ' ';
            } else {
                result += '*';
            }
        }
    
        return result;
    }

    return hasSpace ? 
        maskName(str) : 
        maskString({
            input: str,
            firstUnmaskedIndexChar: 0,
            lastUnmaskedIndexChar: -2,
        });
}

export function maskString({
    input = "default",
    stepUnmaskedIndexChar = 2,
    maskingSymbol = "*"
}) {
    let maskedString = "";

    for (let i = 0; i < input.length; i++) {
        // Unmask the first character and every other character including spaces
        if (i % stepUnmaskedIndexChar === 0) {
            maskedString += input[i];
        } else {
            maskedString += maskingSymbol;
        }
    }
    console.log("🚀 ~ maskString ~ maskedString", maskedString);

    return maskedString;
}




/* 以下是 进行客户端判断 */
export function isMobile() {
    var check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
}

export function isMobileAndroid() {
    var isMobile = {
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
    };

    return isMobile.Android();
}

export function isMobileIOS() {
    var isMobile = {
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
    };

    return isMobile.iOS();
}

export function getE2BBValue() {
    return window.E2GetBlackbox
        ? window.E2GetBlackbox().blackbox == "" ||
          window.E2GetBlackbox().blackbox == undefined
            ? ""
            : window.E2GetBlackbox().blackbox
        : "";
}

export function getMaskHandler(type, value) {
    if (!value) {
        return "";
    }
    const strValue = String(value);
    console.log("🚀 ~ getMaskHandler ~ strValue:", strValue)
    let maskedValue;

    function getStar(number) {
        const statString = Array.from(
            { length: number },
            (cur) => (cur = "*")
        ).join("");
        return statString;
    }
    function formatRealname(name){
		const words = name.split(' ');
		const Initials = words[0][0];
		const stars = '*'.repeat(words[0].length - 1);
		let formattedName = `${Initials}${stars}`;
		if (words.length > 1) {
			for (let i = 1; i < words.length; i++) {
				const initial = words[i][0];
				const wordStars = '*'.repeat(words[i].length - 1);
				formattedName += ` ${initial}${wordStars}`;
			}
		}
		return formattedName;
	}

    switch (type) {
        case "RealName":
            maskedValue = formatRealname(strValue);
            break;
        case "Email":
            const [frontPart, rearPart] = strValue.split("@");
            if (frontPart.length < 3) {
                maskedValue = strValue;
            } else {
                maskedValue =
                    getStar(frontPart.length - 3) +
                    frontPart.slice(frontPart.length - 3) +
                    "@" +
                    rearPart;
            }
            break;
        case "Phone":
            maskedValue =
                getStar(strValue.length -4) +
                strValue.slice(strValue.length - 4);
            break;
        case "Date":
            maskedValue = strValue.length >= 8 ? `**/**/${strValue.slice(6, 8)}**` : strValue;
            break;
        case "Security answer":
        case "Telegram":
            maskedValue = strValue.slice(0, 1) + getStar(strValue.length - 1);
            break;
        default:
            break;
    }

    return maskedValue;
}
/**
 * 半透提示彈窗
 * @param {*} value  展示的文字
 * @param {*} type   布尔值显示成功的还是失败的icon
 * @param {*} zIndex z-index
 * @param {*} key    用于判断样式padding
 * @param {*} name   新增的classname
 * showResultModal("修改成功",true/false) 这样写基本满足部分提示文字少于四个字的
 * authentication-succeeded 够显示4个越南字
 */
export function showResultModal(value, type = true, zIndex = 1501, key="otp", name = "") {
    const opacityMaskDialog = Modal.info({
        title: ``,
        icon: <div />,
        zIndex: zIndex,
        centered: true,
        mask: true,
        footer: null,
        content: (
            <div>
                <img
                    src={`${process.env.BASE_PATH}/img/icons/${
                        type ? "icon-success" : "icon-error"
                    }.png`}
                    width="35px"
                />
                <p
                    style={{
                        marginTop: 10,
                        marginBottom: 0,
                        padding: key == "otp" ? "0" : "0 14px",
                    }}
                >
                    {value}
                </p>
            </div>
        ),
        className: `showInfoModal opacity ${name}`,
    });
    setTimeout(() => {
        opacityMaskDialog.destroy();
    }, 3000);
}

/**
 * 弹窗提示
 * @param {*} status  布尔值显示成功的还是失败的icon和背景色
 * @param {*} value   展示的文字
 * 他的样式类似 message的 info/sussess/fail
 */
export function showSmallResultModal(status,value,bool=false) {
    const opacityMaskDialog =  Modal.info({
        title: ``,
        className: "feedback-status-modal",
        centered: true,
        mask: false,
        width: "unset",
        content: (
            <div
                className="dialog-content"
                style={{
                    backgroundColor: status ? "#DAFFE3" : "rgb(255 218 218)",
                }}
            >
                {status ? (
                    <Icon
                        type="check-circle"
                        theme="filled"
                        style={{ color: "#0CCC3C" }}
                    />
                ) : (
                    <Icon
                        type="close-circle"
                        theme="filled"
                        style={{ color: "red" }}
                    />
                )}
                <p style={{ color: status ? "#0CCC3C" : "red" }}>{value}</p>
            </div>
        ),
        centered: bool
    });
    setTimeout(() => {
        opacityMaskDialog.destroy();
    }, 3000);
}

export function showLargeResultModal(
    status,
    okText,
    cancelText,
    header,
    description,
    onOk,
    onCancel,
    forFreePromotion
) {
    return Modal.confirm({
        title: "",
        width: 400,
        centered: true,
        okText: okText,
        cancelText: cancelText,
        className: "large-feedback-status-modal",
        onOk: onOk,
        onCancel: onCancel,
        cancelButtonProps: { style: { color: "#00A6FF" } },
        content: (
            <>
                <div>
                    <img
                        src={`${process.env.BASE_PATH}/img/icons/${
                            status
                                ? "icon-checked.png"
                                : forFreePromotion
                                ? "icon-error.png"
                                : "icon-warn.png"
                            }`}
                    />
                </div>
                <h3 style={{ marginTop: "15px", fontSize: "18px" }}>
                    <div>{header}</div>
                </h3>
                <div className="note">{description}</div>
            </>
        ),
    });
}

/**
 * 加载中弹窗
 * @param {Boolean} status  true 开启加载弹窗
 * @param {String} text 要显示的文字
 * @returns 
 */
export const blackLoadingPopup =(status,text)=> {
    if(status){
        const opacityMaskDialog = Modal.info({
            title: ``,
            icon: <div />,
            centered: true,
            mask: true,
            footer: null,
            content: (
                <div className="loadimg">
                    <img
                        src={`${process.env.BASE_PATH}/img/icons/loading.gif`}
                        style={{ width: "40px", height: "40px" }}
                        alt="gif"
                    />
                    <p
                        style={{
                            marginTop: 10,
                            marginBottom: 0,
                            padding: 0,
                        }}
                    >
                        {text}
                    </p>
                </div>
            ),
            className: `showInfoModal depositLoading`,
        });
        return opacityMaskDialog;
    }
    return null;
}

const getFileExtension = (f, forceLower=true) => {
    const a = typeof f === 'string' ? f.split('.') : [];
    let s = a.length ? a[a.length-1] : ''
    if (forceLower) {
        s = s.toLowerCase();
    }
    return s;
}

const swapKeyValue = originalObj => Object.entries(originalObj).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
}, {});

const makeFormatSupportValue = (isAvif, isWebp) => ({isAvif, isWebp})

export function getSpecificImageFormat(obj, prefix, supportFormat=makeFormatSupportValue(false, false), aryFormat=['avif', 'webp']) {
    const ooo = swapKeyValue(makeFormatSupportValue('avif', 'webp'))
    const origImg = obj[prefix];
    const ext = getFileExtension(obj[prefix]);

    for (let format of aryFormat) {
        format = typeof format === 'string' ? format.toLowerCase() : '';
        const FIELD = prefix + '_' + format
        const supportKey = ooo[format] || '_';

        /**
         * According to API doc, if original image is avif/webp, then _avif _webp fields WILL BE falsy value.
         * When this case occurs, we need to take original image as output value.
         */
        if (ext === format && origImg) {
            obj[FIELD] = origImg;
        }

        // console.log(obj, prefix, ooo, format, FIELD, supportFormat, supportFormat[supportKey]);
        if (obj[FIELD] && supportFormat[supportKey]) {
            return obj[FIELD]
        }
    }
    return origImg
}

export const AvifCheckerModule = (function(){
    const SESS_KEY = 'storageIsAvifSupported';

    const isAvifSupportedPromise = () => {
        if (!isAvifNotChecked()) {
            return Promise.resolve(isAvifSupported())
        }
        return new Promise(resolve => {
            const img = new Image();
            img.src = "/vn/img/avifTester.avif";
            img.onload = () => { global.sessionStorage?.setItem(SESS_KEY, 'Y'); resolve(true); }
            img.onerror = () => { global.sessionStorage?.setItem(SESS_KEY, 'N'); resolve(false); }
        });
    };

    const isAvifSupported = () => {
        return global.sessionStorage?.getItem(SESS_KEY) === 'Y'
    }
    const isAvifNotSupported = () => {
        return global.sessionStorage?.getItem(SESS_KEY) === 'N'
    }
    const isAvifNotChecked = () => {
        return !isAvifSupported() && !isAvifNotSupported();
    }

    return {
        isAvifSupportedPromise,
    };
})();

//是否支持webp 格式
export function isWebPSupported() {
    if (typeof window === "undefined") return false;
    const elem = document.createElement("canvas");
    if (!!(elem.getContext && elem.getContext("2d"))) {
        // was able or not to get WebP representation
        return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
    }
    return false;
}

/**
 * 公用处理 API Error 来显示
 * @param {Object} error API 返回的error
 * @returns 要显示的具体error内容
 */
export const getDisplayPublicError =(error={})=> {
    error = responseFormatUnifier(error);
    console.log("🚀 ~ file: helper.js:480 ~ getDisplayPublicError ~ error:", error)
    let Msg = translate("系统错误，请联系在线支持！");
    if(error?.errors && Array.isArray(error.errors) && error.errors[0]){
        if(error.errors[0].description){
            Msg = error.errors[0].description
        } 
        else if(error.errors[0].message){
            Msg = error.errors[0].message
        }
    }
    return Msg;
}

/**
 * 游戏在测试环境用http还是https根据当前网址来判断
 * 仅测试环境这样
 * @param {String} PRODUCT_CODE  游戏的Provider code
 * @returns 
 */
export const whichUseHttpForGameLaunch =(PRODUCT_CODE)=> {
    const protocol = window?.location?.protocol;
    if(HostConfig.Config.IsStaging && protocol){
        return `${ApiPort.LOCAL_HOST.replace("https",protocol)}/vn`;
    }
    else {
        return `${ApiPort.LOCAL_HOST}/vn`;
    }
}

export const isJSON =(str)=> {
    if (typeof str == "string") {
        try {
            var obj = JSON.parse(str);
            if (typeof obj == "object" && obj) {
                return obj;
            } else {
                return false;
            }
        } catch (e) {
            console.log("error：" + str + "!!!" + e);
            return false;
        }
    }
    console.log("It is not a string!");
}

export const checkSelfExlusionFn = async () => {
    try {
        const res = await get(ApiPort.GETSelfExclusions);
        if (res && res.result) {
            console.log("selfExclusion res: ", res);
            const isDisabled =
            res.result.status &&
            (res.result.disableDeposit ||
                res.result.disableFundIn ||
                res.result.disableBetting);

            return isDisabled;
        }
        return false; // Return false if no result
    } catch (error) {
        console.error("Error fetching self-exclusion data:", error);
        return false; // Return false on error
    }
};


export function GetGamesList(param = {}, call) {
    let queryString = Qs.stringify(param);
    get(ApiPort.CmsGames + "&" + queryString).then((res) => {
            let gamelists = [];
            if (res.isSuccess) {
                // 在Live環境只顯示isLive = true的遊戲，Stage / SL 則顯示所有遊戲
                gamelists = res?.result?.gameDetails?.filter(item => !HostConfig.Config.ShowAllGames ? item.isLive : item);
                call(gamelists);
            }
        })
        .catch((error) => {
            console.log('GamesList error:', error);
            // Toast.error('获取游戏列表异常');
            return null;
        });
}
export function getStartEndDateByPromotion(cmsRowsData) {
    let startEndDate = [];
    if (cmsRowsData && cmsRowsData.length > 0) {
        let earlierStartDate = cmsRowsData[0].startDate; // "2022-03-28T04:00:00Z"
        let laterEndDate = cmsRowsData[0].endDate;

        for (let k = 0; k < cmsRowsData.length; k++) {
            if (k === 0) {
                continue;
            }

            let data = cmsRowsData[k];

            if (earlierStartDate > data.startDate) {
                earlierStartDate = data.startDate;
            }

            if (laterEndDate < data.endDate) {
                laterEndDate = data.endDate;
            }
        }

        let earlierStartDateOnly = earlierStartDate.substr(0, earlierStartDate.indexOf('T'));
        let laterEndDateOnly = laterEndDate.substr(0, laterEndDate.indexOf('T'));
        startEndDate = [earlierStartDateOnly, laterEndDateOnly];
    }

    if (!startEndDate || startEndDate.length <= 0) {
        startEndDate = [moment().subtract(180,'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    }
    return startEndDate
}
export function getStartEndDateByNow() {
    let startDate = moment(new Date())
                .subtract(90, 'd')
                .startOf('day')
                .utcOffset(8)
                .format('YYYY-MM-DDTHH:mm:ss'),
        endDate = moment(new Date()).utcOffset(8).format('YYYY-MM-DDTHH:mm:ss');
    return [startDate, endDate];
}

export function pageData(data, pageNumner, pageSize) {
    return data.slice((pageNumner - 1) * pageSize, pageNumner * pageSize);
}

export function calculateTimeDifference(strDate) {
    const currentTime = moment();
    const targetTime = moment(moment(strDate), "YYYY-MM-DD HH:mm");
    const duration = moment.duration(targetTime.diff(currentTime));

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();

    return `${days.toString().padStart(2, '0')}  ngày ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export function decorateBffscBonusV2Api4ForwardCompatible(res) {
    if (res && Array.isArray(res.result)) {
        const mapping = {
            // see doc /wiki/x/pwAzG
            bonusCategoryID: 'bonus_CategoryID',
            bCreatedBy: 'BCreatedBy',
            bUpdatedBy: 'BUpdatedBy',
            bDateCreated: 'BDateCreated',
            bDateUpdated: 'BDateUpdated',
            bonusReleaseTypeID: 'Bonus_ReleaseTypeID',
            bonusRuleGroupId: 'groupID',
        };
        res.result = res.result.map(v => {
            for (let K in mapping) {
                const isLackK = !Object.prototype.hasOwnProperty.call(v||{}, K) || typeof v[K]==='undefined' || v[K]===null;
                const newK = mapping[K];
                if (isLackK && Object.prototype.hasOwnProperty.call(v||{}, newK)) {
                    v[K] = v[newK];
                }
            }
            return v;
        })
    }
    return res
}

export function eliminateBackendResponseRedundantKeyInResultField(res, key) { // for ForwardCompatible
    if (res && res.result && res.result[key]) {
        let directResult = res.result[key];
        for (let k of Object.keys(res.result)) {
            if (k===key) { continue; }
            directResult[k] = res.result[k];
        }
        res.result = directResult;
    }
    return res;
}

export function showSuccessToast(msg)  {
    let txt = msg || 'Khuyến mãi đã bắt đầu.';
    toast.success( txt, {
        style: {minHeight: "30px", padding: "0px 30px", width: "fit-content", fontSize: "12px",},
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Zoom,
        closeButton: false, // Hide the close icon
        icon: <img src="/vn/img/1wallet/00/successToastIcon.svg" alt="icon-success" />,
        className: "custom-toast-OW-project",
      });
}

export function showErrorToast(msg, icon = null)  {
    let txt = msg || 'Hệ thống hiện không khả dụng vì lý do nội bộ, xin vui lòng thử lại sau';
    toast.error(txt, {
        style: {minHeight: "30px", padding: "0px 30px", width: "fit-content", fontSize: "12px"},
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Zoom,
        closeButton: false, // Hide the close icon
        icon: icon || <img src="/vn/img/1wallet/00/info-yellow.svg" alt="info-yellow" />,
        className: "custom-toast-OW-project yellowInfo",
      });
}

function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}

export function getEndDayOfMonth(year, month) {
    let end = 31;
    if (month==2) {
        end = isLeapYear(year) ? 29 : 28
    } else if (month==4 || month==6 || month==9 || month==11) {
        end = 30;
    }
    return end;
}