import { Modal, message } from "antd";
import Router from "next/router";
import fetch from "isomorphic-unfetch";
import { getQueryVariable,Cookie,getDisplayPublicError, AvifCheckerModule, isWebPSupported } from "$ACTIONS/helper";
import { CMSAPIUrl } from "./TLCAPI";
import { LogPost } from "./Log";
import { translate } from "$ACTIONS/Translate";
import { normalizeBffscResponse, responseFormatUnifier } from "./util";
import HostConfig from "./Host.config";

//这个些API 如果返回400，则返回给函数内部处理。不在这里使用公共的message显示
const APIERRORLIST = [
    "api/Payment/Application/UploadAttachment",
    // "api/Promotion/ManualPromo",
    "api/Auth/ForgetUsername/Email",
    "api/Auth/ChangePassword",   //otp重置密码
    "api/Promotion/DailyDeals", //申请每日好礼
]
message.config({
    top: 50,
    maxCount: 1,
});

//需要 auto-login 的 API
const aryNeedAutoLogoutApiUrl = ['/api/Announcement/Announcements', '/api/PersonalMessage/InboxMessages'];

const makeFormatSupportValue = (avif, webp) => ({avif, webp})
function recursiveFillOriginalImageFieldAsOtherSupportFormat(o, supportFormats=makeFormatSupportValue(false, true)) {
    if (Array.isArray(o)) {
        return o.map(v => recursiveFillOriginalImageFieldAsOtherSupportFormat(v, supportFormats))
    } else if (o && typeof o === 'object') {
        let keys = Object.keys(o);
        let aryFields4AvifWebp = [];
        const priorityExt = ['avif', 'webp'];
        for (let k of keys) {
            const camel = [k + 'Avif', k + 'Webp'];
            const snake = [k + '_avif', k + '_webp'];
            const isCamel = camel.every(_ => keys.includes(_));
            const isSnake = snake.every(_ => keys.includes(_));
            let item, currentExt = typeof o[k] === 'string' ? o[k].split('.').slice(-1)?.[0] : '';
            currentExt = typeof currentExt === 'string' ? currentExt.toLowerCase() : '';
            if (isCamel) {
                item = camel
            } else if (isSnake) {
                item = snake
            } else {
                continue;
            }

            for (let i=0; i<priorityExt.length; i++) {
                let ext = priorityExt[i];

                if (ext === currentExt && o[k]) {
                    o[item[i]] = o[k]
                }

                let isSupport = supportFormats[ext];
                if (isSupport && currentExt === 'gif' && ext === 'avif') { isSupport = false; } // since API server does not convert to animated-avif yet
                if (isSupport && o[item[i]]) {
                    o[k] = o[item[i]] // Forward Compatibility: still take o[k] as final output
                    break;
                }
            }
            if (!o[k]) {
                o[k] = location.origin + '/vn/img/logo.svg';
            }
        }

        for (let k of Object.keys(o)) {
            o[k] = recursiveFillOriginalImageFieldAsOtherSupportFormat(o[k], supportFormats)
        }

        return o;
    }
    return o
}

const make4xx5xxObj = (j, httpstatus) => Object.assign({Json: j}, {Http: httpstatus});

export default function request(method, url, body, token, moreConf = {}) {
    const localToken = localStorage.getItem("access_token");
    let redirectToken = null;

    localToken && (redirectToken = getQueryVariable("redirectToken"));
    method = method.toUpperCase();
    const IS_GET_METHOD_REQUEST = method === 'GET';
    if (method === "GET") {
        body = undefined;
    } else {
        body = body && JSON.stringify(body);
    }
    //区分两种Api CMS/Flash
    let isCms = url.startsWith(HostConfig?.CMSAPIUrl);
    let isCaptcha = url.indexOf('/api/v1.0/') != -1;

    let header;
    header = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Culture: "vi-vn",
        token: "71b512d06e0ada5e23e7a0f287908ac1",
    };
    if (token || JSON.parse(localToken)) {
        header["Authorization"] = token || JSON.parse(localToken);
    }
    //仅Flash会添加
    if (!isCms && !isCaptcha) {
        let IS_USE_FvCBffKey = ['?', '&', '&amp;'].some(_ => typeof url==='string' && url.toLowerCase().includes(_ + 'api-version=8.0'))
        if (IS_USE_FvCBffKey) {
            header["x-bff-key"] = 'FvCImRbajDiysV9ttN728w==';
        } else {
            header["x-bff-key"] = "51EXaTN7NUeCbjnvg95tgA==";
        }
    }

    //Api日志
    let logData = {
        method: method,
        responseStatus: 0,
        request_time: new Date(),
        responseData: null,
    };
    const apiUrl = url;

    return timeout_fetch(
        fetch(url, {
            method,
            headers: header,
            body,
        })
    )
        .then((res) => {
            //記下 responseStatus
            if (res && res.status) {
                logData.responseStatus = res.status;
            }

            if(res.status === 401 && aryNeedAutoLogoutApiUrl.includes(url)){ 
                message.error(translate("请重新登录，访问过期！"), 3);
                setTimeout(() => {
                    global.globalExit();
                    Router.push("/");
                }, 1500);
                return Promise.reject({});
            }

            if (
                res.status === 401 ||
                res.status === 400 ||
                res.status === 503 ||
                res.status === 403 ||
                res.status === 428
            ) {
                let json = res.json();
                if (moreConf.IS_STILL_RETURN_JSONVALUE_IF_HTTP4xx5xx && res.status >= 400 && res.status <=599 ) {
                    return json.then(j => make4xx5xxObj(j, res.status));
                }
                return json.then(Promise.reject.bind(Promise));
            }
            if (res.status === 500) {
                if (moreConf.IS_STILL_RETURN_JSONVALUE_IF_HTTP4xx5xx) {
					let json = res.json();
					return json.then(j => make4xx5xxObj(j, res.status));
				}
                // 根据某些需求，某些api响应500状态码异常情况不需要报错。
                if (
                    !APIERRORLIST.some((apiErrVal) => ~url.indexOf(apiErrVal))
                ) {
                    // message.error(translate("系统错误，请稍后重试！"));
                    //Router.push('/');
                } else {
                    return res.json();
                }
            } else if (res.status === 504) {
                // message.error(translate('网络错误，请检查网络后重试！'));
                //Router.push('/');
            } else if (res.status === 404) {
                message.error(translate("未找到相关API，请稍后重试！"));
            } else {
                if (typeof CMSAPIUrl === 'string' && CMSAPIUrl
					&& typeof url==='string' && url
					&& url.toLowerCase().indexOf(CMSAPIUrl.toLowerCase()) === 0
					&& IS_GET_METHOD_REQUEST
					&& res.status === 200
					) {
					// As long as it is CMS API response, we need: dynamic image path & support other image formats
					return res.json().then(j=>{
						return AvifCheckerModule.isAvifSupportedPromise().then(b => {
							return [b, isWebPSupported(), j]
						})
					}).then(([isAvif, isWebP, j]) => {
						return recursiveFillOriginalImageFieldAsOtherSupportFormat(j, makeFormatSupportValue(isAvif, isWebP));
					});
				}
                return res.json();
            }
        })
        .then((response) => {
            if (response) {
                logData.responseData = response; // 保持原始資料
                LogPost(apiUrl, body, logData);
                if (typeof CMSAPIUrl === 'string' && CMSAPIUrl
					&& typeof url==='string' && url
					&& url.toLowerCase().indexOf(CMSAPIUrl.toLowerCase()) === 0
					&& IS_GET_METHOD_REQUEST
					) {
                    response = makeDynamicStrapiResponse(response);
                }
                return response;
            } else {
                logData.responseData = {};
                LogPost(apiUrl, body, logData);
                return response;
            }
        })
        .catch((error) => {
            logData.error = error;
            error = responseFormatUnifier(error);
            //避免日誌拿到空的error
            if (error && JSON.stringify(error) === "{}") {
                let newError = {};
                if (error.message) {
                    newError.message = error.message;
                }
                if (error.stack) {
                    newError.stack = error.stack;
                }
                if (JSON.stringify(newError) !== "{}") {
                    logData.error = newError;
                }
            }
            LogPost(apiUrl, body, logData);
            if (error.redirect) {
                var searchURL = window.location.search;
                searchURL = searchURL.substring(1, searchURL.length);
                var targetPageId = searchURL.split("&")[0].split("=")[1];
                if (error.redirect.Code == "GEN0003") {
                    if (targetPageId) {
                        window.location.href =
                            error.redirect.message + "?aff=" + targetPageId;
                    } else {
                        window.location.href = error.redirect.message;
                    }
                }
            }
            // 公共错误处理
            if (
                APIERRORLIST.some((apiErrVal) => ~url.indexOf(apiErrVal)) && getDisplayPublicError(error)
            ) {
                return Promise.reject(error);
            }

            if (error && error.errors && error.errors.length != 0) {
                const ERRORCODE = error.errors[0].errorCode;
                switch (ERRORCODE) {
                    case 'GEN0001':
                    	// 维护界面
                        Cookie.Create("maintainTime", error.error_details.RetryAfter,1)
                    	HttpStatus = 3;
                    	Router.push('/');
                    	break;
                    case "GEN0002":
                        // 不允许访问（地域限制）
                        HttpStatus = 4;
                        Router.push("/");
                        break;
                    case "GEN0005":
                    case "GEN0006":
                    case "IDSVR00006":
                    case "VAL99902":
                    case "VAL18014":
                    case "VAL99903":
                        if (!redirectToken) {
                            message.error(translate("请重新登录，访问过期！"), 3);
                            setTimeout(() => {
                                global.globalExit();
                                Router.push("/");
                                Router.reload();
                            }, 1500);
                        }
                        return Promise.reject(error);
                    case "MEM00141":
                    case "MEM00140":
                    //     message.error(
                    //         error.errors[0].message || translate( "您的账户已根据账户管理政策关闭了。")
                    //     );
                    //     localStorage.setItem(
                    //         "RestrictAccessCode",
                    //         ERRORCODE
                    //     );
                    //     Router.push(`/RestrictAccess`);
                    //     break;
                    // MEM00141 和 MEM00140 的逻辑阶段二开发。当前登录遇到的话先只做错误提示
                        return error;
                    case "MEM00145":
                        Nonecsbutton = true;
                        HttpStatus = 4;
                        global.globalExit();
                        Router.push("/");
                        break;
                    case "GEN0008":
                        if (window.location.href.indexOf("Safehouse") > -1) {
                            message.error(error.errors[0].message, 4);
                            global.redirectDomin &&
                                typeof global.redirectDomin === "function" &&
                                setTimeout(() => {
                                    global.redirectDomin();
                                }, 4000);
                        }
                        break;

                    case "MEM00004":
                    case "MEM00059":
                    case "MEM00060":
                    case "SafeHouseLoginFailed":
                        // Modal.info({
                        // 	className: 'confirm-modal-of-public',
                        // 	icon: <div />,
                        // 	title: '登入失败',
                        // 	centered: true,
                        // 	okText: '确认',
                        // 	zIndex: 2000,
                        // 	content: (
                        // 		<div
                        // 			style={{
                        // 				textAlign: 'center'
                        // 			}}
                        // 			dangerouslySetInnerHTML={{
                        // 				__html: error.errors[0].description
                        // 			}}
                        // 		/>
                        // 	)
                        // });
                        // GeetestFaile();
                        // error.json();
                        // break;

                        return Promise.reject(error);
                    //待處理PII問題
                    // case "PII00702":
                    //     Router.push("/RestrictAccess");
                    //     global.globalBlackListExit();
                    //     break;

                    //添加银行卡
                    case "VAL11056":
                        message.error(translate("银行账户已存在"));
                        break;
                    // 忘記密碼/忘記用戶名
                    case "VAL00001":
                    case "VAL08025":
                    case "VAL08026":
                    case "VAL03012":
                    case "VAL03001":
                    case "MEM00051":
                    case "P109001":
                    case "P109003":
                    case "VAL13014":
                    case "VAL13007":
                    case "VAL08052":
                    case "VAL03016": //重置密码 新密码不能与旧密码相同
                    case "VAL18015": // 手機驗證嘗試超過五次
                    case "VAL18016": // 驗證碼已發送，五分鐘後再嘗試
                    case "VAL18024": // 手機驗證碼失效
                    case "P103001":
                    case "P103114":
                    case "VAL11012":
                    case "SNC0002":
                    case "VERI9999": // usercenter无法生成安全码
                    case "P103106":
                    case "P101103":
                    case "VAL18023":
                    case "VAL18013":
                    case "P103103":
                    case "P101104":
                    case "P103109":
                    case "P103110":
                    case "P103111":
                    case "P103112":
                    case "P103113":
                    case "P103114":
                    case "P103115":
                    case "P101106":
                    case "P101053":
                    case "P101019":
                    case "P101017":
                    case "P106003":
                    case "SNC0001":
                    case "SNC0002":
                    case "SNC0003":
                    case "P111001":
                    case "VAL13034":
                    case "VAL13035":
                    case "MEM00061":
                    case "VAL14001": // 获取最新的推荐好友Quelea推荐活动详情
                        return error;
                    case "MEM00026":
                        message.error(translate("用户名已被其他人使用，请重新输入"));
                        break;
                    case "MEM00041":
                        message.error(translate("电子邮件地址不可用，请尝试使用其他电子邮件地址"));
                        break;
                    case "MEM00050":
                        message.error(translate("您并未修改资料"));
                        break;
                    default:
                        message.error(error.errors[0].description);
                }
            } else if (error.message) {
                if (
                    error.status == "BAD_REQUEST" &&
                    (error.code == "63401" || error.code == "63403") &&
                    error.path == "/api/v1.0/judgement"
                ) {
                    // 登录图形滑动验证 确认滑动坐标的API，验证错误
                    // return error.json();
                    return Promise.reject(error);
                } else {
                    !(url || '').includes('CustomerService') && message.error(error.message);
                }
            }
        });
}

const hasKey = (o, k) => Object.prototype.hasOwnProperty.call(o, k);
const callbackResponseFromAdvRequest = (res) => {
    // let httpstatus;
    if (res && hasKey(res,'Http')
        && hasKey(res,'Json')) {
        // httpstatus = res.Http;
        let ADDON = normalizeBffscResponse(res.Json);
        res = res.Json || {}; // do not delete `|| {};`
        res.ADDON = ADDON;
    } else if (res && (hasKey(res, 'isSuccess') || hasKey(res, 'IsSuccess'))) {
        // for 1wallet project, some error response is under HTTP200, so we also append ADDON here:
        let {
            ADDON = normalizeBffscResponse(res),
            b = normalizeBffscResponse.isEmpty(ADDON)
        } = {};
        res[b ? 'NO_ADDON' : 'ADDON'] = b ? true : ADDON;
    }
    return res || {};
};

// GET 请求
export const get = (url, moreConf) => request('GET', url, undefined, undefined, moreConf);
export const advget = (url, moreConf) => get(url, {...moreConf, IS_STILL_RETURN_JSONVALUE_IF_HTTP4xx5xx:true}).then(callbackResponseFromAdvRequest);
// POST 请求
export const post = (url, body, token, moreConf) => request("POST", url, body, token, moreConf);
export const advpost = (url, body, token, moreConf) => post(url, body, token, {...moreConf, IS_STILL_RETURN_JSONVALUE_IF_HTTP4xx5xx:true}).then(callbackResponseFromAdvRequest);
// PATCH 请求
export const patch = (url, body, moreConf) => request("PATCH", url, body, undefined, moreConf);
export const advpatch = (url, body, moreConf) => patch(url, body, {...moreConf, IS_STILL_RETURN_JSONVALUE_IF_HTTP4xx5xx:true}).then(callbackResponseFromAdvRequest);
// PUT 上传
export const put = (url, body, moreConf) => request("PUT", url, body, undefined, moreConf);
// DELETE 删除
export const del = (url, body) => request("DELETE", url, body);

export const timeout_fetch = (fetch_promise, timeout = 600000) => {
    let timeout_fn = null;
    let timeout_promise = new Promise(function (resolve, reject) {
        timeout_fn = function () {
            reject({ message: translate("网络错误，请重试") });
        };
    });
    let abortable_promise = Promise.race([fetch_promise, timeout_promise]);
    setTimeout(
        function () {
            timeout_fn();
        },
        timeout
    );

    return abortable_promise;
};

/**
 * @description 將Strapi response中寫死的asset domain 轉換成動態 https://cache.{current domain}
 * 需轉換domain如下:
 * @imageDomain https://cache.uiwhed.com"  
 * @slDomain https://cmsapisl.fun88.biz, https://cmsapisl2.fun88.biz
 * @stagDomain https://cmsapistag.fun88.biz, https://cmsapistag1.fun88.biz, https://cmsapistag2.fun88.biz, https://cmsapistag3.fun88.biz, https://cmsapistag4.fun88.biz
 * 
 * @param {*} res api response
 */
function makeDynamicStrapiResponse(res) {
    if (!CMSAPIUrl.includes("cache.")) {
        return res;
    }

    // 定義需要匹配的域名正則表達式
    const needToDynamicAssetDomains = [
        "cache\\.uiwhed\\.com",
        "cmsapisl[2]?\\.fun88\\.biz",
        "cmsapistag[1-4]?\\.fun88\\.biz"
    ];

    try {
        // 將 response 轉成字串
        let stringRes = JSON.stringify(res);

        for (let domain of needToDynamicAssetDomains) {
            const regex = new RegExp(`http(s)?:\/\/${domain}`, "gi");
            if (regex.test(stringRes)) {
                // 如果找到匹配的域名，替換並返回
                stringRes = stringRes.replace(regex, CMSAPIUrl);
                break;
            }
        }

        // 將處理後的字串轉回 JSON 並返回
        return JSON.parse(stringRes);
    } catch (error) {
        console.error("Failed to parse response:", error);
        return res; // 若解析失敗，返回原始資料
    }
}