/**
*  copy from components/Wallet/Withdrawal.js then modify
*/

import { enumWalletProductGroupId } from "./constantsData";

export function sendPiwikEventWithEventValue(category, action, name, evtValue, aryCustomVariable) {
    global.Pushgtagdata && global.Pushgtagdata(category, action, name, evtValue, aryCustomVariable);
}
export function sendPiwikEvent(category, action, name) {
    global.Pushgtagdata && global.Pushgtagdata(category, action, name);
}
export function sendPiwikUrl(url, title) {
    global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(url, title);
}

export function sendPiwikEventAtUrl(url, title, category, action, name) {
    if (global.Pushgtagpiwikurl && global.Pushgtagdata) {
        global.Pushgtagpiwikurl(url, title);
        global.Pushgtagdata(category, action, name);
    }
}
export function sendPiwikEventWithEventValueAtUrl(url, title, category, action, name, evtValue, aryCustomVariable) {
    if (global.Pushgtagpiwikurl && global.PushgtagdataWithEventValue) {
        global.Pushgtagpiwikurl(url, title);
        global.PushgtagdataWithEventValue(category, action, name, evtValue, aryCustomVariable);
    }
}


export const piwikNavBar = {
    run1: () => sendPiwikEvent('NavBar', `Click Balance Type`, `NavBar_C_BalanceType`),
    run2: () => sendPiwikEvent('NavBar', `Refresh Balance`, `NavBar_C_RefreshBalance`),
    run3: () => sendPiwikEvent('NavBar', `View Balance Tooltip`, `NavBar_C_BalanceToolTip`),
    run4: () => sendPiwikEvent('NavBar', `View Locked Balance Tooltip`, `NavBar_C_LockedBalanceTooltip`),
    run5: () => sendPiwikEvent('NavBar', `View Locked Balance Detail`, `NavBar_C_LockedBalance`),
//    run6: () =>
    run7: (walletProductGroupId) => {
            let customVariableValue = '';
            if (walletProductGroupId) {
                customVariableValue = Object.entries(enumWalletProductGroupId).find(v=>v[1].ID==walletProductGroupId)?.[1].piwikName
            }
            if (!customVariableValue) {return;}
            sendPiwikEventWithEventValue('NavBar', `Choose Balance Type`, `NavBar_BalanceType_C_Choose`, null, [{
                customVariableKey:'NavBar_BalanceType_C_Choose',
                customVariableValue
            }]);
        },
};

export const piwikLockedBalance = {
    runUrl: () => sendPiwikUrl('locked_balance', 'Locked Balance'),
    run6: () => sendPiwikEvent('MemberCenter_LockedBalance', 'View Promotion Time Tooltip', 'MemberCenter_LockedBalance_C_PromotionTimeTooltip')
};
export const piwikPromotion = {
    runUrlPromo: (PromoName) => sendPiwikUrl(`promotion/${PromoName}`, `Promotion Page - ${PromoName}`),
    runUrlApplied: () => sendPiwikUrl('promotion_applied_popup', 'Promotion Applied Popup'),
    runUrlClaimed: () => sendPiwikUrl('bonus_claimed_popup', 'Bonus Claimed Popup'),
    runUrlSentOrHitPersonalMax: () => sendPiwikUrl('promotion_sent_or_personal_max_popup', 'Promotion Sent or Hit Personal Max'),
    runUrlInProcess: () => sendPiwikUrl('promotion_application_in_process_popup', 'Promotion Application In Process Popup'),
    runUrlRebateNotEnough: () => sendPiwikUrl('promotion_application_rebate_not_enough_popup', 'Promotion Application Rebate Not Enough Popup'),
    runUrlApplicationFull: () => sendPiwikUrl('promotion_application_full_popup', 'Promotion Application Full Popup'),
    runUrlExpired: () => sendPiwikUrl('promotion_expired_popup', 'Promotion Expired Popup'),
    //
    run1: () => sendPiwikEvent('Promotion', 'Apply Promotion', 'Promotion_C_ApplyNow'),
    run3: (PromoName, PrmoID) => sendPiwikEventWithEventValue('Promotion', 'Submit Promotion Form', 'Promotion_S_Application', null, [{
        customVariableKey:'Promotion_S_Application_PromoName_PrmoID',
        customVariableValue:PromoName + '_' + PrmoID,
    }]),
    run4: (PromoName, PrmoID) => sendPiwikEventWithEventValue('Promotion', 'Start Promotion​', 'Promotion_C_StartPromotion​', null, [{
        customVariableKey:'Promotion_C_StartPromotion_PromoName_PrmoID​',
        customVariableValue:PromoName + '_' + PrmoID,
    }]),
    run5: () => sendPiwikEvent('Promotion', 'Promotion Rebate Not Enough', 'Promotion_Application_Popup_RebateNotEnough_C_OK'),
    run6: () => sendPiwikEvent('Promotion', 'Promotion Application Full', 'Promotion_Application_Popup_Full_C_OK'),
    run7: () => sendPiwikEvent('Promotion', 'Promotion Expired', 'Promotion_Expired_Popup_C_OK'),
};
export const piwikBonus = {
    runUrlBonus: () => sendPiwikUrl('bonus', 'Bonus'),
    runUrlCancelBonus: () => sendPiwikUrl('confirm_cancel_bonus_popup', 'Confirm Cancel Bonus Popup'),
    //
    run2: () => sendPiwikEvent('Bonus', 'View Ready to Start Bonus', 'Bonus_Category_C_ReadytoStart'),
    run3: () => sendPiwikEvent('Bonus', 'View Ongoing Bonus', 'Bonus_Category_C_Ongoing'),
    run4: () => sendPiwikEvent('Bonus', 'View Ready to Claim Bonus', 'Bonus_Category_C_Ready'),
    run5: () => sendPiwikEvent('Bonus', 'View Complete Bonus', 'Bonus_Category_C_Complete'),
    run6: () => sendPiwikEvent('Bonus', 'View Bonus Detail', 'Bonus_C_Detail'),
    run7: (PromoName, PrmoID) => sendPiwikEventWithEventValue('Bonus', 'Start Promotion', 'Bonus_C_StartPromotion', null, [{
        customVariableKey:'Bonus_C_StartPromotion_PromoName_PrmoID',
        customVariableValue:PromoName + '_' + PrmoID
    }]),
    run8: () => sendPiwikEvent('Bonus', 'Contact CS (Text Link)', 'Bonus_Empty_C_CS'), // TODO: need make call UI part first, then callable
    run9: () => sendPiwikEvent('Bonus', 'Confirm Rebate Game Type', 'Bonus_C_ConfirmRebateGameType'),
    run10: (ErrorMsg) => sendPiwikEventWithEventValue('Bonus', 'Confirm Bonus Type and Cancel Ongoing', 'Promotion_C_ConfirmType_CancelOnging', null, [{
        customVariableKey:'Promotion_C_ConfirmType_CancelOnging_ErrorMsg',
        customVariableValue:ErrorMsg,
        // ErrorMsg, ex: EVO真人尚有投注未结算，请稍后再试
    }]), // TODO
    run11: (ErrorMsg) => sendPiwikEventWithEventValue('Bonus', 'Confirm Cancel Sign Up Bonus', 'Bonus_C_ConfirmCancelSignUpBonus', 'Bonus_C_ConfirmCancelSignUpBonus_ErrorMsg', null, [{
        customVariableKey:'Bonus_C_FilterCategory',
        customVariableValue:ErrorMsg,
        // ErrorMsg, ex: EVO真人尚有投注未结算，请稍后再试
    }]), // TODO
    run12: (BonusCategory) => sendPiwikEventWithEventValue('Bonus', 'Choose Bonus Category','Bonus_C_FilterCategory', null, [{
        customVariableKey:'Bonus_C_FilterCategory',
        customVariableValue:BonusCategory,
        // ex: Total, Sports/Esports, Live Dealer, P2P/InstantGames/Fishing, Slots, Lottery
    }]),
    run15: (ErrorMsg) => sendPiwikEventWithEventValue('Bonus', 'Cancel Bonus', 'Bonus_C_Cancel', null, [{
        customVariableKey:'Bonus_C_Cancel_ErrorMsg',
        customVariableValue:ErrorMsg,
        // ex: EVO真人尚有投注未结算，请稍后再试
    }]), // TODO
    run16: () => sendPiwikEvent('Bonus', 'Click Bonus Kebab Menu', 'Bonus_Cancel_Bonus_Popup_C_Confirm'),
    run17: () => sendPiwikEvent('Bonus', 'Confirm Cancel Bonus and Rebate Record', 'Bonus_C_ConfirmCancelBonusAndRebateRecord'),
    run19: () => sendPiwikEvent('Bonus', 'Claim Bonus', 'Bonus_C_ClaimBonus'),
    run20: () => sendPiwikEvent('Bonus', 'Go to Locked Balance', 'Bonus_ClaimBonusSuccess_C_LockedBalance'),
    run21: (GameType) => sendPiwikEventWithEventValue('Bonus', 'Filter Archived Bonus', 'Bonus_Archived_C_Category', 'Bonus_Archived_C_Category', null, [{
        customVariableKey:'Bonus_Archived_C_Category',
        customVariableValue:GameType,
        // ex: Total, Sports/Esports, Live Dealer, P2P/InstantGames/Fishing, Slots, Lottery
    }]),
};
export const piwikMessage = {
    run1: () => sendPiwikEvent('MemberCenter', 'View Message (Bonus)', 'MemberCenter_Message_Notice_C_Bonus'),
    run2: () => sendPiwikEvent('MemberCenter', 'Mark All Read', 'MemberCenter_Message_Tab_C_Read'),
    run3: () => sendPiwikEvent('MemberCenter', 'View Message Detail', 'MemberCenter_Message_C_Detail'),
    run4: () => sendPiwikEvent('MemberCenter', 'Close Message Detail', 'MemberCenter_Message_C_CloseDetail'),
    run5: (BonusStatus) => sendPiwikEventWithEventValue('Message', 'View Bonus Detail', 'Message_C_BonusDetail', null, [{
        customVariableKey:'Message_C_BonusDetailStatus',
        customVariableValue:BonusStatus,
        //  ex: 已過期、已完成、已取消
    }]),
};
// export const piwikInGame = {};


export const CallFnWhenEmpty = ({ onEmpty }) => {
    React.useEffect(() => {
        onEmpty();
    }, [onEmpty]);
    return null;
    };