import { message, Modal } from "antd";
import { get, post, patch } from "$ACTIONS/TlcRequest";
import { ApiPort, APISET, APISETS } from "$ACTIONS/TLCAPI";
import { oneWalletAPISET } from "../actions/TLCAPI";
import { convertToV1Result } from "../actions/util";
import moment from "moment";
import { advget, advpost } from "../actions/TlcRequest";
import promotionErrorConfigModule from "../components/promotionModalConfigModule";
import { getE2BBValue } from "../actions/helper";

/**
 * 获取余额
 * @param {Function} call 回调函数
 * @param {Function} setLoadingStatus 设置Loading状态的函数
 * @returns {Promise} 返回Promise对象
 */
export async function GetAllBalance(call, setLoadingStatus, ) {
    try {
        setLoadingStatus && setLoadingStatus(true);
        let res = await get(ApiPort.GETBalance + oneWalletAPISET);
        res = convertToV1Result(res)
        typeof call === "function" && call(res);
        return res;
    } catch (error) {
        console.log("GetAllBalance error:", error);
        return null;
    } finally {
        setLoadingStatus && setLoadingStatus(false);
    }
}

export async function GetAllBalanceV2(call) {
    try {
        let res = await get(ApiPort.GETBalance + oneWalletAPISET);
        typeof call === "function" && call(res);
        return res;
    } catch (error) {
        console.log("GetAllBalance error:", error);
        return null;
    } 
}
export async function GETWalletProductGroups() {
    try {
        const res = await get(ApiPort.GETWalletProductGroups);
        return res;
    } catch (error) {
        console.log("GETWalletProductGroups error:", error);
        return null;
    }
}

// 获取提款验证相关数据
export function GetWithdrawalVerification(call, setLoadingStatus) {
    setLoadingStatus && setLoadingStatus(true);
    get(ApiPort.WithdrawalVerification + APISET)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("GetWithdrawalVerification error:", error);
            return null;
        })
        .finally(() => {
            setLoadingStatus && setLoadingStatus(false);
        });
}

// 获取支付方式
export function GetPayList(call) {
    get(ApiPort.GETPaymentlistAPI)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("GetPayList error:", error);
            return null;
        });
}

/**
 * 获取支付渠道默认值
 * @param {object} payMethodsDetail 当前支付方式详情
 */
export function GetAvailableMethods(payMethodsDetail) {
    if (
        payMethodsDetail &&
        Array.isArray(payMethodsDetail.availableMethods) &&
        payMethodsDetail.availableMethods.length
    ) {
        if (
            payMethodsDetail.availableMethods.length >= 2 &&
            payMethodsDetail.code !== "CTC"
        ) {
            if (
                payMethodsDetail.availableMethods[0] == "DEFAULT" ||
                payMethodsDetail.availableMethods[0].methodCode == "DEFAULT"
            ) {
                return payMethodsDetail.availableMethods[1].methodCode;
            } else {
                return payMethodsDetail.availableMethods[0].methodCode;
            }
        } else {
            return payMethodsDetail.availableMethods[0].methodCode;
        }
    }
}

// 获取支付方式的详情
export function GetPayDetail(type, call, payListOrValue) {
    let MethodCode = "";
    if (Array.isArray(payListOrValue)) {
        const payMethodsDetail = payListOrValue.find(
            (item) => item.code === type
        );
        MethodCode = GetAvailableMethods(payMethodsDetail) || "";
    } else if (typeof payListOrValue === "string") {
        MethodCode = payListOrValue;
    }

    const apa =
        ApiPort.GETDepositDetailsAPI +
        type +
        "&MethodCode=" +
        MethodCode +
        "&isMobile=" +
        (type === "BCM" ? "true" : "false") +
        "&hostName=" +
        ApiPort.LOCAL_HOST;

    get(
        ApiPort.GETDepositDetailsAPI +
            type +
            "&MethodCode=" +
            MethodCode +
            "&isMobile=" +
            (type === "BCM" ? "true" : "false") +
            "&hostName=" +
            ApiPort.LOCAL_HOST
    )
        .then((res) => {
            call(res);
            console.log("bctype=====", type);
            console.log("apa===", apa);
        })
        .catch((error) => {
            console.log("GetPayDetail error:", error);
            return null;
        });
}

// 获取目标账户列表
export function GetWalletList(call) {
    const localWalletList = localStorage.getItem("walletList");
    localWalletList === null || localWalletList === ""
        ? get(ApiPort.GETWallets)
              .then((res) => {
                  if (res) {
                      localStorage.setItem("walletList", JSON.stringify(res));
                      call(res);
                  }
              })
              .catch((error) => {
                  console.log("GetWalletList error:", error);
                  return null;
              })
        : call(JSON.parse(localWalletList));
}

// 获取可申请优惠列表
export function GetWalletBonus(AccountType, call, transactionType) {
    get(
        `${ApiPort.BonuslistAPI}&transactionType=` +
            transactionType +
            "&wallet=" +
            AccountType
    )
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("GetWalletBonus error:", error);
            return null;
        });
}

// 提交充值 || 提交提款
export function CommonPostPay(data, call) {
    post(ApiPort.POSTApplications, data)
        .then((res) => {
            console.log("res==commonpostpaydata", data);
            console.log("res==commonpostpay", res);
            call(res);
            if (!res.isSuccess) {
                // call(res);
                //return data.transactionType !== "Withdrawal" && message.error(res.errorMessage || "数据异常，请联系在线客服！");
                /*let displayErrorMsg = res.errorMessage
      console.log("res",res)
      if (displayErrorMsg.indexOf("小同客服")>-1 && res.isPopup) {
        let errorMessageArr = displayErrorMsg.split("小同客服")
        displayErrorMsg = errorMessageArr.map((item, index) => {
          if (index != errorMessageArr.length-1) {
            return <><span className="content-text">{item}</span><a onClick={()=>{global.PopUpLiveChat()}} ><span style={{ fontWeight : "bold" }}>小同客服</span></a></>	
          } else {
            return <span>{item}</span>
          }
          
        });
      }*/
                let displayErrorMsg = res.errorMessage;
                if (res.isPopup) {
                    displayErrorMsg = (
                        <span>
                            抱歉，由于您还有未完成的充值记录，暂时无法重复提交。若您已转账成功，请
                            <a
                                onClick={() => {
                                    global.PopUpLiveChat();
                                }}
                            >
                                <span style={{ fontWeight: "bold" }}>
                                    联系小同客服
                                </span>
                            </a>
                            。
                        </span>
                    );
                }
                if (res.errors) {
                    displayErrorMsg =
                        res.errors[0].description ||
                        res.errors[0].message ||
                        " 抱歉，系统忙碌中，建议您使用其他存款方式";
                }

                if (
                    data.transactionType !== "Withdrawal" &&
                    data.paymentMethod !== "SR" &&
                    data.paymentMethod !== "PPB" &&
                    res.errorCode !== "P101007"
                ) {
                    if (
                        (data.methodcode === "AliBnBQR" ||
                            data.methodcode === "WCBnBQR") &&
                        res.errorCode === "P101105"
                    ) {
                        Modal.confirm({
                            title: "温馨提示",
                            icon: null,
                            centered: true,
                            okText: "返回充值首页",
                            cancelText: "在线客服",
                            cancelButtonProps: {
                                type: "primary",
                                ghost: true,
                                onClick: () => {
                                    paybnbConfirm.destroy();
                                    global.PopUpLiveChat();
                                },
                            },
                            content: (
                                <div style={{ textAlign: "left" }}>
                                    {displayErrorMsg}
                                </div>
                            ),
                            onOk: () => {},
                            onCancel: () => {},
                        });
                    } else {
                        message.error(displayErrorMsg);
                        // Modal.info({

                        // 	className: 'confirm-modal-of-public',
                        // 	icon: <div />,
                        // 	okText: '关闭',
                        // 	title: <div style={{ textAlign: 'center' }}>温馨提示</div>,
                        // 	content: <div style={{ textAlign: 'center' }}>{<p>{displayErrorMsg}</p>}</div>
                        // });
                    }
                }
            } 
            // else {
            //     call(res);
            // }
        })
        .catch((error) => {
            console.log("CommonPostPay error:", error);
            return null;
        });
}

// 提交充值确认
export function CommonPostConfirmPay(data, call) {
    post(
        ApiPort.POSTPaymentConfirmStep +
            "ConfirmStep" +
            APISET +
            `&transactionId=${data.transactionId}`,
        data
    )
        .then((res) => {
            res && call(res);
            if (res.isSuccess) {
                message.success(
                    "订单成立！交易正在进行中，您的存款将在指定时间内到账，感谢您的耐心等待！",
                    5
                );
            } else {
                message.error("输入的旧账号错误！");
            }
        })
        .catch((error) => {
            console.log("CommonPostConfirmPay error:", error);
            return null;
        });
}

// 提交转账
export function TransferSubmit(data, call) {
    post(ApiPort.POSTTransfer, data)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("TransferSubmit error:", error);
            call(error)
        });
}

// 获取用户已绑定银行卡
export function GetMemberBanks(call) {
    get(ApiPort.GETMemberBanksfirst)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("GetMemberBanks error:", error);
            return null;
        });
}

// 获取提款方式
export function GetWithdrawalMethods(call) {
    get(ApiPort.GETCanWithdrawalPay)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("GetWithdrawalMethods error:", error);
            return null;
        });
}

//获取提现泰达币的汇率
export function GetWithdrawalExchangeRate(data, call) {
    get(
        ApiPort.GetExchangeRate +
            "?CurrencyFrom=" +
            data.CurrencyFrom +
            "&CurrencyTo=" +
            data.CurrencyTo +
            "&baseAmount=" +
            data.baseAmount +
            APISETS
    )
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("GetWithdrawalExchangeRate error:", error);
            return null;
        });
}

// 获取某个方式的提款详情
export function GETWithdrawalDetails(data, type, call) {
    get(
        ApiPort.GETWithdrawalDetailsAPI +
            "&method=" +
            data +
            "&MethodCode=" +
            type +
            "&isMobile=false&hostName=" +
            ApiPort.LOCAL_HOST
    )
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("GETWithdrawalDetails error:", error);
            return null;
        });
}

// 查询会员虚拟货币錢包
export function CheckExchangeRateWallet(data, call) {
    get(
        ApiPort.CheckExchangeRateWallet +
            "?cryptoCurrencyCode=" +
            data.CryptoCurrencyCode +
            APISETS
    )
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("CheckExchangeRateWallet error:", error);
            return null;
        });
}

// 添加虚拟货币錢包
export function AddExchangeRateWallets(data, call) {
    post(ApiPort.AddExchangeRateWallet, data)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("AddExchangeRateWallet error:", error);
            return null;
        });
}

//设置默认钱包
export function setTDBDefaultWallet(data, call) {
    patch(`${ApiPort.setTDBDefaultWallet}${APISET}&walletId=${data.WalletID}`)
        .then((res) => {
            call(res);
        })
        .catch((erros) => {
            console.log("setTDBDefaultWallet error:", error);
            return null;
        });
}

//用户的提款户口达到了限制设定
export function getWithdrawalSetUp(data, call) {
    get(
        ApiPort.CheckWithdrawalThreshold +
            "?BankAccountNumber=" +
            data.BankAccountId +
            APISETS
    )
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
}
//虚例币支付二取消交易我已成功充值提交
export function InvoiceAutDeposit(data, call) {
    post(
        ApiPort.InvoiceAutCryptoDeposit +
            "?transactionID=" +
            data.transactionID +
            "&transactionHash=" +
            data.transactionHash +
            APISETS
    )
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("InvoiceAutCryptoDeposit error:", error);
            return null;
        });
}

//虚例币支付二取消交易
export function CancelTheDealMethod(data, call) {
    post(
        ApiPort.InvoiceAutCancelTheDeal +
            "?transactionId=" +
            data.transactionId +
            APISETS
    )
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("虚例币支付二取消交易:", error);
            return null;
        });
}

// 上传文件
export function PostUploadAttachment(data, call) {
    post(ApiPort.UploadAttachment, data)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("PostUploadAttachment error:", error);
            return null;
        });
}


export async function CheckBonusEligibleViaEnrollment({ id }, call = () => {}) {
    if (!id) {
        console.error("Invalid parameter: id is required.");
        return null;
    }

    try {
        const res = await post(
            `${ApiPort.BonusEnrollment}&actionType=1`,
            { entryPeriodId: id }
        );
        console.log(`[CheckBonusEligibleViaEnrollment] Response for entryPeriodId ${id}:`, res);
        call(res);
        return res;
    } catch (error) {
        console.error(`[CheckBonusEligibleViaEnrollment] Error for entryPeriodId ${id}:`, error.message);
        return null;
    }
}

export async function EnrollmentWithNoFormForManualItem({ id }, call = () => {}) {
    if (!id) {
        console.error("Invalid parameter: id is required.");
        return null;
    }

    let postData = {
        entryPeriodId: id,
        siteId: 37,
        formData: {
            formType: 6,
        }
    };

    try {
        const res = await advpost(
            `${ApiPort.BonusEnrollment}&actionType=2`,
            postData
        );
        console.log(`[EnrollmentWithNoFormForManualItem] Response for entryPeriodId ${id}:`, res);
        call(res);
        return res;
    } catch (error) {
        console.error(`[EnrollmentWithNoFormForManualItem] Error for entryPeriodId ${id}:`, error.message);
        return null;
    }
}

export async function CheckReloadBonusEligible({ id }, call = () => {}) {
    if (!id) {
        console.error("Invalid parameter: id is required.");
        return null;
    }

    try {
        const res = await advget(
            `${ApiPort.GETBonusApplicationsEligible + id}`
        );
        console.log(`[CheckReloadBonusEligible] Response for ReloadBonus ${id}:`, res);
        call(res);
        return res;
    } catch (error) {
        console.error(`[CheckReloadBonusEligible] Error for ReloadBonus ${id}:`, error.message);
        return null;
    }
}

export async function EnrollmentWithFormForManualItem( postData, call = () => {}) {
    console.log(`[EnrollmentWithFormForManualItem] Request for entryPeriodId:`, postData);
    try {
        const res = await advpost(
            `${ApiPort.BonusEnrollment}&actionType=2`,
            postData
        );
        console.log(`[EnrollmentWithNoFormForManualItem] Response for entryPeriodId:`, res);
        call(res);
        return res;
    } catch (error) {
        console.error(`[EnrollmentWithNoFormForManualItem] Error for entryPeriodId:`, error.message);
        return null;
    }
}

export const handleWaitToStatyErrorCode = (result) => {
    let errorCode = result?.errorCode;
    let config;
  
    switch (errorCode) {
      case "CP30021":
        config = promotionErrorConfigModule.AssignedClaimHitMaxError;
        if (result?.message && result?.message !== "") {
            config = {
              ...config,
              content: (
                  <div style={{ paddingTop: "44px" }}>
                      <h3 style={{ textAlign: "center" }}>Đã đạt giới hạn đăng ký</h3>
                      <p
                          style={{
                              textAlign: "center",
                              marginBottom: "32px",
                              color: "#666666",
                          }}
                      >
                          {result?.message}
                      </p>
                  </div>
              )
            };
          }
        break;
      case "CP30014":
      case "CP30015":
        config = promotionErrorConfigModule.AssignedClaimExpiryErrorAfterSubmitInWaitingForm;
        if (result?.message && result?.message !== "") {
            config = {
              ...config,
              content: (
                  <div style={{ paddingTop: "44px" }}>
                      <h3 style={{ textAlign: "center" }}>Đăng ký không thành công.</h3>
                      <p
                          style={{
                              textAlign: "center",
                              marginBottom: "32px",
                              color: "#666666",
                          }}
                      >
                          {result?.message}
                      </p>
                  </div>
              )
            };
          }
        break;
      case "CP30003":
        config = promotionErrorConfigModule.AssignedClaimAlreadyRecieved;
        if (result?.message && result?.message !== "") {
            config = {
              ...config,
              content: (
                  <div style={{ paddingTop: "44px" }}>
                      <h3 style={{ textAlign: "center" }}>Khuyến mãi đã được nhận.</h3>
                      <p
                          style={{
                              textAlign: "center",
                              marginBottom: "32px",
                              color: "#666666",
                          }}
                      >
                          {result?.message}
                      </p>
                  </div>
              )
            };
          }
        break;
      default:
        return { showModal: false };
    }

  
    return {
      showModal: true,
      config,
    };
  };
  


export const getPreBonusCancellationEligibility = (()=>{
    const f = async (item) => {
        let id = item?.playerBonusId;
        try {
            let res = await advget(ApiPort.GETBonusCancellationEligibility + id);
            if (Object.prototype.hasOwnProperty.call(res.result||{}, 'data')) {
                res.result = Object.assign({}, res.result, res.result.data);
            }
            return res;
        } catch (e) {
            console.error(e)
            return;
        }
    };
    f.getCaseResult = res => { // implement image-20240412-035524.png table; TODO: re-check
        let {
            result = res?.result || {},
            isTurnoverProgress = result.isTurnoverProgress,
            applyAmount = result.applyAmount, //本金
            bonusGivenAmount = result.bonusGivenAmount, //紅利
            winningAmount = result.winningAmount, //盈利
            finalBalance = applyAmount + bonusGivenAmount + winningAmount,
            isLose = isTurnoverProgress && winningAmount < 0,
            isWin = isTurnoverProgress && winningAmount > 0,
            isNotLose = isTurnoverProgress && winningAmount >= 0,
            isNoBet2WinLose = !isTurnoverProgress,
            canceledFinalBalance = isNotLose
                                    ? applyAmount
                                    : Math.max(0, applyAmount + winningAmount),
            content,
            tooltip,
            pullbackAmount = result?.pullbackAmount,
        } = {};

        return {
            isTurnoverProgress,
            applyAmount, //本金
            bonusGivenAmount, //紅利
            winningAmount, //盈利
            finalBalance,
            isLose,
            isWin,
            isNotLose,
            isNoBet2WinLose,
            canceledFinalBalance,
            content,
            tooltip,
            pullbackAmount,
        };
    }
    return f;
})();

export function HandleManalPreBonuseErrorModule(errorCode) {
    switch (errorCode) {
        case "CP30001":
        case "BP00025":
        case "CP30002":
            return {
                showModal: true,
                config: promotionErrorConfigModule.PromotionErrorKeepPreviousStatus,
            };
        case "CP30003":
            return {
                showModal: true,
                config: promotionErrorConfigModule.PromotionErrorParticipateOnce,
            };
        case "CP30004":
        case "CP30005":
        case "CP30006":
        case "CP30007":
        case "CP30008":
        case "CP30014":
        case "CP30015":
            return {
                showModal: true,
                config: promotionErrorConfigModule.PromotionErrorExpired,
            };

        default:
            return {
                showToast: true,
            };
    }
}

export function HandleReloadErrorModule(errorCode) {
    switch (errorCode) {
        case "inSufficientBalance":
            return {
                showModal: true,
                config: promotionErrorConfigModule.InsufficientBalanceErrorForReloadBonusForm,
            }
        case "BP00122":
            return {
                showModal: true,
                config: promotionErrorConfigModule.ReloadBonusExpiry,
            };
        case "BP00025":
            return {
                showModal: true,
                config: promotionErrorConfigModule.ReloadBonusInProgress,
            };
        case "BP00005":
            return {
                showModal: true,
                config: promotionErrorConfigModule.ReloadBonusReachMax,
            };
        case "BP00013":
            return {
                showModal: true,
                config: promotionErrorConfigModule.ReloadBonusAlreadySentOrHitPerMax,
            };
        case "successToast": {
            return {
                showToast: true,
                config: {
                    msg: "Đăng ký thưởng thành công!",
                    icon: "/vn/img/1wallet/00/successToastIcon.svg"
                }
            };
        }
        default:
            return {
                showToast: true,
            };
    }
}
export async function EnrollBonus({ id, formData }, call) {
    if (!id || !formData) {
        console.error("Invalid parameters: id and formData are required.");
        return null;
    }
    try {
        const res = await post(
            `${ApiPort.BonusEnrollment}&actionType=2`,
            {
                entryPeriodId: id,
                siteId: 37,
                formData: formData,
            }
        );
        console.log("EnrollBonus", res);
        if (typeof call === "function") {
            call(res);
        }
        return res;
    } catch (error) {
        console.error("EnrollBonus error:", error);
        return null;
    }
}

export const createBonusApplications = (()=>{
    const f = async ({bonusId, amount, targetWallet, bonusGroupId} , opt) => {
        let e2BlackBoxValue = getE2BBValue()
        console.log("createBonusApplications",e2BlackBoxValue, bonusId, amount, targetWallet, bonusGroupId, opt);
        opt = opt || {}; // bonusMode isMax couponCode
        let body = {
            blackBox: e2BlackBoxValue,
            e2BlackBoxValue,
            bonusId,
            bonusGroupId,
            amount,
            targetWallet,
        };
        if (Object.prototype.hasOwnProperty.call(opt, 'bonusMode')) {
            body.bonusMode = opt.bonusMode;
        }
        if (Object.prototype.hasOwnProperty.call(opt, 'isMax')) {
            body.isMax = opt.isMax;
        }
        if (Object.prototype.hasOwnProperty.call(opt, 'couponCode')) {
            body.couponCode = opt.couponCode;
        }
        if (body.bonusMode==='Transfer') {
            // dead code
            body.transferBonus = {
                fromWallet: 'MAIN', // TODO
                transactionId: '12412341264', // TODO
                isFreeBet: false
            };
        } else if (body.bonusMode==='Deposit') {
            body.depositBonus = {
                depositCharges: 2, // TODO
                depositId: '12412341264', // TODO
            };
            // body.successBonusId = 1412356856; // TODO // sample request does not present successBonusId
        }
        return await advpost(ApiPort.POSTBonusApplications, body);
        // /wiki/x/KABoGg
    }
    f.isApplicationOK = res => res && res.isSuccess && typeof res.result?.bonusResult?.message==='string' && res.result?.bonusResult?.message.toLowerCase()==='success';
    return f;
})();

export function BonusProgressDataHandler(result, call) {
    let catKey ;
    const getTitle = (data) => {
    const categoryTitles = {
        ADJUSTMENT: "Thưởng từ hệ thống", // Vietnamese title for "ADJUSTMENT"
        REFERRAL: "Giới Thiệu Bạn Bè",    // Vietnamese title for "REFERRAL"
        REBATE: "Thưởng Hoàn Trả",        // Vietnamese title for "REBATE"
        REWARDPOINT: "Cược miễn phí đổi từ Điểm thưởng"
    };
    if (typeof data?.contractCategory == "string") { 
        catKey = data?.contractCategory.toUpperCase();    
    }
    if(catKey === "PREBONUS" || catKey === "POSTBONUS") {
        return data?.title;
    }
    // Return the title based on category, or fallback to data.title, or a default
    return categoryTitles[catKey] || "Thưởng từ hệ thống";
    };

    if (!result) {
        console.error("Invalid data: result is required.");
        return null;
    }

    const progressData = result.contract.map((item, index) => ({
        key: String(index + 1),
        category: item?.walletProductGroupName,
        bonusName:  getTitle(item),
        bonusTime: `${new Date(item.applyDateTime).toLocaleDateString("en-GB")} ${new Date(item.applyDateTime).toLocaleTimeString("en-GB", {
            hour12: false,
        })}`, // Format date as 'DD/MM/YYYY HH:mm'
        bonusAmount: item.initialBalance,
        neededAmount: item.contractBalance,
        progress: item.turnoverProgress,
        requiredTurnover: item.requiredTurnover,
        currentTurnover: item.currentTurnover,
        expiredDateTime: item.expiredDateTime ? `${new Date(item.expiredDateTime).toLocaleDateString("en-GB")} ${new Date(item.expiredDateTime).toLocaleTimeString("en-GB", {
            hour12: false,
          })}` : null, // Format date as 'DD/MM/YYYY HH:mm'
      }));

    if (typeof call === "function") {
        call(progressData);
    }

    return progressData;
}

export async function GetCampaignListWithId( id ) {
    try {
        // Uncomment the following line to use the mock instead of the API
        // const res = mockingData;

        // Use actual API call
        const res = await get(ApiPort.campaignList+ `&campaignId=${id}`);
        console.log("GetCampaignListGetCampaignList", res);

        typeof call === "function" && call(res);
        return res
    } catch (error) {
        console.error("GetCampaignList error:", error);

        // Return mock data in case of API failure
        return null;
    }
}

export async function checkManualBonusEligibility( id , call = () => {}) {
    if (!id) {
        console.error("Invalid parameter: id is required.");
        return null;
    }
    try{
        const res = await advpost(ApiPort.BonusEnrollment + `&actionType=1`, { entryPeriodId: Number(id) });
        console.log("checkManualBonusEligibility", res);
        if(typeof call === "function"){
            call(res);
        }
        return res;

    }catch(error){
        console.error("checkManualBonusEligibility error:", error);
        return null;
    }
}

export async function GetBonusAssignedClaimWithId(id, call) {
    try {
        // Uncomment the following line to use the mock instead of the API
        // const res = mockingData;

        // Use actual API call
        const res = await get(ApiPort.AssingedClaims + `&participantId=${id}`);
        console.log("GetBonusAssignedClaimWithId", res);

        typeof call === "function" && call(res);
        return res
    } catch (error) {
        console.error("GetBonusAssignedClaimWithId error:", error);

        // Return mock data in case of API failure
        return null;
    }

}    

export async function GetBonusAssignedClaims(call) {
    try {
        // Uncomment the following line to use the mock instead of the API
        // const res = mockingData;

        // Use actual API call
        const res = await get(ApiPort.AssingedClaims);
        console.log("AssingedClaims", res);

        typeof call === "function" && call(res);
        return res
    } catch (error) {
        console.error("AssingedClaims error:", error);

        // Return mock data in case of API failure
        return null;
    }
}


// 定義圖標的對應表
const ICONS_ASSETS = {
    Sports: `/vn/img/promotions/icon/icon-Sports.png`,
    Esports: `/vn/img/promotions/icon/icon-Esports.png`,
    P2P: `/vn/img/promotions/icon/icon-P2P.png`,
    Fishing: `/vn/img/promotions/icon/icon-Fishing.png`,
    InstantGames: `/vn/img/promotions/icon/icon-InstantGames.png`,
    KENO: `/vn/img/promotions/icon/icon-Lottery.png`,
    SLOT: `/vn/img/promotions/icon/icon-Slot.png`,
    LD: `/vn/img/promotions/icon/icon-Casino.png`,
};

// 定義類別與對應圖標的映射
const ICONS_MAPPING = {
    SB: ["Sports", "Esports"], // SB 顯示 Sports 和 Esports
    P2P: ["P2P", "InstantGames", "Fishing"], // P2P 顯示 P2P、Fishing 和 InstantGames
    KENO: ["KENO"],
    SLOT: ["SLOT"],
    LD: ["LD"],
};

export const CardIcons = ({ productGroups }) => {
    if (!productGroups) return null;

    // 根據第一個 productGroup 確定對應的圖標類別
    const mainGroup = productGroups?.productGroup;
    const relatedGroups = ICONS_MAPPING[mainGroup] || []; // 獲取相關類別

    return (
        <div className="card-icons">
            {relatedGroups.map((group, index) =>
                ICONS_ASSETS[group] ? (
                    <img
                        key={index}
                        src={ICONS_ASSETS[group]}
                        alt={group}
                        className="card-icon"
                    />
                ) : null
            )}
        </div>
    );
};

export const getCampaignAssignedClaims = (()=>{
    const f = async ({page, pageSize, participantId=0}) => {
        console.log("getCampaignAssignedClaims", page, pageSize, participantId);
    try {
        let url = participantId
            ? ApiPort.GETCampaignAssignedClaimsByParticipantId + participantId
            : ApiPort.GETCampaignAssignedClaims;
        if (page && pageSize) {
            url += '&currentPage=' + page + '&perPageSize=' + pageSize
        }
        return await advget(url);
    } catch (error) {
        console.error(error);
        return null;
    }
    }
    f.decorateResponse = res => {
        if (!Object.prototype.hasOwnProperty.call(res, 'bonusGiven')
            && Object.prototype.hasOwnProperty.call(res, 'bonusGivenAmount')
        ) {
            res.bonusGiven = res.bonusGivenAmount
        }
        return res;
    }
    return f;
})();

export async function GetBonusProgress(call) {
    // Mocking data
    const mockingData = {
        result: {
            totalContractBalance: 0,
            totalDepositContractBalance: 0,
            contract: [
                {
                    id: 24264,
                    contractCategory: "ADJUSTMENT",
                    walletProductGroupId: 1,
                    walletProductGroupName: "余额",
                    applyDateTime: "2023-12-07T06:22:18.117Z",
                    expiredDateTime: "2999-01-01T00:00:00Z",
                    initialBalance: 1111,
                    contractBalance: 1111,
                    requiredTurnover: 1111,
                    currentTurnover: 0,
                    turnoverProgress: 0,
                },
                {
                    id: 24264,
                    contractCategory: "ADJUSTMENT",
                    walletProductGroupId: 1,
                    walletProductGroupName: "余额",
                    applyDateTime: "2023-12-07T06:22:18.117Z",
                    expiredDateTime: "2999-01-01T00:00:00Z",
                    initialBalance: 1111,
                    contractBalance: 1111,
                    requiredTurnover: 1111,
                    currentTurnover: 20,
                    turnoverProgress: 35,
                },
                {
                    id: 24264,
                    contractCategory: "REFERRAL",
                    walletProductGroupId: 1,
                    walletProductGroupName: "余额",
                    applyDateTime: "2023-12-07T06:22:18.117Z",
                    expiredDateTime: "2999-01-01T00:00:00Z",
                    initialBalance: 1111,
                    contractBalance: 1111,
                    requiredTurnover: 1111,
                    currentTurnover: 10,
                    turnoverProgress: 60,
                },
                {
                    id: 24264,
                    contractCategory: "REFERRAL",
                    walletProductGroupId: 1,
                    walletProductGroupName: "余额",
                    applyDateTime: "2023-12-07T06:22:18.117Z",
                    expiredDateTime: "2999-01-01T00:00:00Z",
                    initialBalance: 1111,
                    contractBalance: 1111,
                    requiredTurnover: 1111,
                    currentTurnover: 30,
                    turnoverProgress: 100,
                },
                {
                    id: 24264,
                    contractCategory: "REBATE",
                    walletProductGroupId: 1,
                    walletProductGroupName: "余额",
                    applyDateTime: "2023-12-07T06:22:18.117Z",
                    expiredDateTime: "2999-01-01T00:00:00Z",
                    initialBalance: 1111,
                    contractBalance: 1111,
                    requiredTurnover: 1111,
                    currentTurnover: 0,
                    turnoverProgress: 0,
                },            {
                    id: 24264,
                    contractCategory: "ADJUSTMENT",
                    walletProductGroupId: 1,
                    walletProductGroupName: "余额",
                    applyDateTime: "2023-12-07T06:22:18.117Z",
                    expiredDateTime: "2999-01-01T00:00:00Z",
                    initialBalance: 1111,
                    contractBalance: 1111,
                    requiredTurnover: 1111,
                    currentTurnover: 0,
                    turnoverProgress: 0,
                },            {
                    id: 24264,
                    contractCategory: "ADJUSTMENT",
                    walletProductGroupId: 1,
                    walletProductGroupName: "余额",
                    applyDateTime: "2023-12-07T06:22:18.117Z",
                    expiredDateTime: "2999-01-01T00:00:00Z",
                    initialBalance: 1111,
                    contractBalance: 1111,
                    requiredTurnover: 1111,
                    currentTurnover: 0,
                    turnoverProgress: 0,
                },
            ],
        },
        isSuccess: true,
    };

    try {
        // Uncomment the following line to use the mock instead of the API
        // const res = mockingData;

        // Use actual API call
        const res = await get(ApiPort.BonusProgress);
        console.log("GetBonusProgress", res);

        typeof call === "function" && call(res);
        // return mockingData;
        return res?.isSuccess ? res : mockingData;
    } catch (error) {
        console.error("GetBonusProgress error:", error);

        // Return mock data in case of API failure
        return mockingData;
    }
}

export async function fetchBonusDetailWithPlayerBonusId(id) {
    try {
        const dateFrom = moment().subtract(1, 'year').format("YYYY-MM-DD"); // One year ago from today
        const dateTo = moment().format("YYYY-MM-DD");                      // Today's date
    
        const url = `${ApiPort.AppliedHistory}&dateFrom=${dateFrom}&dateTo=${dateTo}&playerBonusId=${id}`;
        
        const response = await get(url);
        console.log("fetchBonusDetailWithPlayerBonusId", response);
        return response;
    } catch (e) {
        console.error(e);
    }
}

export async function getAssignedBonusDetailWithId(campaignId) {
    try {
        const response = await advget(ApiPort.GETPromotionDetailByCampaignId + campaignId);
        console.log("getAssignedBonusDetailWithId", response);
        return response;
    } catch (e) {
        console.error(e);
    }
}

function applyOrCancelCampaign(participantId, productGroup, type='APPLY') {
    return async function () {
        try {
            let body = {
                participantId,
                productGroup,
                blackboxValue: getE2BBValue(),
            };
            body.isCancel = type==='CANCEL';
            return await advpost(ApiPort.POSTCampaignApplications, body);
        } catch (e) {
            console.error(e)
            return;
        }
    }
}

export function SignUpBonusClickToCancelConfig () {
    return promotionErrorConfigModule.SignUpConfrimedToCancelModal;
}

export const checkSignUpBonus = (()=>{
    const f = async (productGroup) => {
        try {
            if (!productGroup) {
                alert('lack productGroup');
                return;
            }
            return await advget(ApiPort.GETMemberServingSignupBonusStatus + productGroup);
        } catch (e) {
            console.error(e)
            return;
        }
    };
    f.isSignupBonus = res => res && res.isSuccess && res.result;
    return f;
})();

export const applyCampaign = (()=>{
    const f = async (participantId, productGroup) => {
        return await applyOrCancelCampaign(participantId, productGroup, 'APPLY')();
    }
    f.isStarting = res => !!(res && res.isSuccess && res.result?.isSuccess); // res.result.message = "优惠已开始"
    return f;
})();

export const cancelCampaign = (()=>{
    const f = async (participantId, productGroup) => {
        return await applyOrCancelCampaign(participantId, productGroup, 'CANCEL')();
    }
    return f;
})();

export const cancelBonus = (()=>{
    const f = async (playerBonusId) => {
        try {
            return await advpost(ApiPort.POSTBonusCancellation + '&playerBonusId=' + playerBonusId);
        } catch (e) {
            console.error(e)
            return;
        }
    }
    f.isCancelSuccess = res => res.isSuccess && res.result?.isSuccess;
    return f;
})();

export const claimBonus = (()=>{
    const f = async (playerBonusId) => {
        return await advpost(ApiPort.POSTBonusClaim, {playerBonusId});
    };
    f.isClaimed = res => res.isSuccess && res.result?.isClaimed;
    return f;
})();

    export const getCampaignAssignedClaimsData = async () => {
        let cache = []; // Array to store all fetched data
        let page = 1;   // Start from page 1
        let pageSize = 50;
        let hasData = true;
        let maxAttempts = 99; // Max number of API calls
        let attempts = 0; // Counter for attempts
    
        while (hasData && attempts < maxAttempts) {
            let res = await getCampaignAssignedClaims({ page, pageSize });
    
            if (res?.isSuccess && res?.result?.isSuccess) {
                let data = res?.result?.data;
    
                if (data.length > 0) {
                    cache.push(...data); // Merge new data into cache
                    page++; // Fetch next page
                    attempts++; // Increase attempt count
                } else {
                    hasData = false; // Stop when API returns an empty array
                }
            } else {
                hasData = false; // Stop loop if API call fails
            }
        }
        cache = cache.filter(item => 
            item?.claimEndDateUtc && moment(item.claimEndDateUtc).isAfter(moment.utc())
        );

        console.log("Final cached responses:", cache); // Log the final data cache
        console.log(`Total attempts: ${attempts}`);
        return cache;

    }