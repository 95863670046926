import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { set } from "lodash";
import { APISET } from "../actions/TLCAPI";
import get from "central-payment/Main/lib/FieldForm/rc-util/utils/get";
//这三个pagekey的页面不在个人中心
//当跳转到这些pagekey所代表的页面时Key 需变成 userinfo
export const WILLUPDATETODEFAULT_KEYARRAY = [
    "mypromotion",
    "mybonus",
    "dailybonus",
];
export const DEFAULT_TABKEY = "userinfo";
import { checkSelfExlusionFn } from "../actions/helper";
import { GetAllBalanceV2, GETWalletProductGroups } from "../data/wallet";
import { enumWalletProductGroupId } from "../actions/constantsData";

let changeableKey = DEFAULT_TABKEY;
if (typeof Storage !== "undefined") {
    changeableKey = !!sessionStorage.getItem("userCenterPageKey")
        ? sessionStorage.getItem("userCenterPageKey")
        : DEFAULT_TABKEY;
}

export const fetchMemberSelfExlusionStatus = createAsyncThunk(
    "userCenter/fetchMemberSelfExlusionStatus",
    async () => {
        const isDisabled = await checkSelfExlusionFn();
        return isDisabled;
    }
);

export const fetchMemeberBalacnce = createAsyncThunk(
    "userCenter/fetchAllBalances",
    async (_, { dispatch }) => {
        try {
            // Fetch both APIs concurrently
            const [balanceRes, groupDataRes] = await Promise.all([
                GetAllBalanceV2(),
                GETWalletProductGroups(),
            ]);

            console.log("balanceRes", balanceRes);
            console.log("groupDataRes", groupDataRes);

            if (
                groupDataRes?.isSuccess &&
                groupDataRes?.result &&
                groupDataRes?.result.length > 0
            ) {
                dispatch(
                    userCenterActions.setWalletProductGroups(
                        groupDataRes?.result
                    )
                );
            }

            if (
                balanceRes?.isSuccess &&
                balanceRes?.result &&
                typeof balanceRes?.result
                    ?.isWithdrawalContractBalanceBlocking === "boolean"
            ) {
                dispatch(
                    userCenterActions.setToggleOnOff(
                        balanceRes?.result?.isWithdrawalContractBalanceBlocking
                    )
                );
            }

            // Ensure both API responses are successful
            if (
                balanceRes?.isSuccess &&
                balanceRes?.result &&
                groupDataRes?.isSuccess &&
                groupDataRes?.result
            ) {
                // Create a mapping of wallet product group IDs to codes
                const groupCodeMap = groupDataRes.result.reduce((acc, item) => {
                    acc[item.walletProductGroupId] =
                        item.walletProductGroupCode;
                    return acc;
                }, {});

                // Update balances with group codes and main wallet data
                const updatedBalances = balanceRes.result.balances.map(
                    (item) => {
                        if (
                            item.walletProductGroupId ===
                            enumWalletProductGroupId.MAIN.ID
                        ) {
                            return {
                                ...item,
                                walletProductGroupName: "Tổng Số Dư",
                                walletProductGroupCode: "TOTAL",
                                balance: balanceRes.result.totalBalance,
                                usableAmount:
                                    balanceRes.result.withdrawableBalance,
                                lockedBalance:
                                    balanceRes.result.totalContractBalance,
                            };
                        }
                        return {
                            ...item,
                            walletProductGroupCode:
                                groupCodeMap[item.walletProductGroupId] || null,
                        };
                    }
                );

                /**
                 * MAIN 錢包目前不可以加在 balances 裡面，
                 * 會導致用 balances render 的選單多出不必要的Main選項
                 * 因此在這邊獨立處理
                 */
                const mainWalletItem = balanceRes.result.balances.find(
                    (balance) =>
                        balance.walletProductGroupId ===
                        enumWalletProductGroupId.MAIN.ID
                );
                const MAIN = {
                    balance: mainWalletItem.balance,
                    lockedBalance: mainWalletItem.lockedBalance,
                    usableAmount: mainWalletItem.usableAmount,
                };

                // Combine the updated balances with the rest of the balance info
                const updatedBalanceInfo = {
                    ...balanceRes.result,
                    balances: updatedBalances,
                    MAIN,
                };
                console.log("updatedBalanceInfo", updatedBalanceInfo);

                // Dispatch the updated balance information
                dispatch(userCenterActions.setBalanceInfo(updatedBalanceInfo));

                // Return the updated balance info
                return updatedBalanceInfo;
            } else {
                // Handle API errors
                const errorMessage =
                    balanceRes?.errors?.[0]?.description ||
                    groupDataRes?.errors?.[0]?.description ||
                    "Failed to fetch balance information.";
                console.error(errorMessage);

                // Dispatch null balance info to reset the state
                dispatch(userCenterActions.setBalanceInfo(null));
                throw new Error(errorMessage);
            }
        } catch (error) {
            // Handle unexpected errors
            console.error("Error fetching balances:", error);
            dispatch(userCenterActions.setBalanceInfo(null));
            throw error; // Propagate the error for further handling
        }
    }
);

const userCenterSlice = createSlice({
    name: "userCenter",
    initialState: {
        memberInfo: {},
        walletProductGroups: [],
        isPersonalDataEditable: false, //帳戶資料-基本訊息是否可編輯
        userCenterPageTabKey: changeableKey,
        refreshCurrentPage: "", //当前页面是否需要重新请求一次数据 (充值： deposit，转账：transfer，提款：withdraw)
        isDisableFromSelfExclude: null,
        balance: null,
        balanceInfo: null,
        loading: false,
        error: null,
        showLockBalanceModal: false,
        toggleOnOff: false,
    },
    reducers: {
        setMemberInfo(state, action) {
            state.memberInfo = action.payload;
            const { firstName, dob, gender } = action.payload;
            if (firstName.length && dob && gender) {
                state.isPersonalDataEditable = false;
            } else {
                state.isPersonalDataEditable = true;
            }
        },
        changeUserCenterTabKey(state, action) {
            state.userCenterPageTabKey = WILLUPDATETODEFAULT_KEYARRAY.some(
                (item) => item === action.payload
            )
                ? DEFAULT_TABKEY
                : action.payload;
            sessionStorage.setItem(
                "userCenterPageKey",
                state.userCenterPageTabKey
            );
        },
        setRefreshCurrentPage(state, action) {
            state.refreshCurrentPage = action.payload;
        },
        resetData(state) {
            state.memberInfo = {};
            state.isPersonalDataEditable = false;
            state.userCenterPageTabKey = DEFAULT_TABKEY;
            state.refreshCurrentPage = "";
        },
        setIsDisableFromSelfExclude(state, action) {
            state.isDisableFromSelfExclude = action.payload;
        },
        setBalanceInfo(state, action) {
            console.log("Setting balanceInfo:", action.payload);
            state.balanceInfo = action.payload;
        },
        setShowLockBalanceModal(state, action) {
            state.showLockBalanceModal = action.payload;
        },
        setToggleOnOff(state, action) {
            state.toggleOnOff = action.payload;
        },
        setWalletProductGroups(state, action) {
            state.walletProductGroups = action.payload;
        },
        setKeyValue: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMemberSelfExlusionStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                fetchMemberSelfExlusionStatus.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.isDisableFromSelfExclude = action.payload;
                }
            )
            .addCase(
                fetchMemberSelfExlusionStatus.rejected,
                (state, action) => {
                    state.loading = false;
                    state.error = action.error.message;
                }
            )
            .addCase(fetchMemeberBalacnce.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMemeberBalacnce.fulfilled, (state, action) => {
                state.loading = false;
                state.balance = action.payload;
            })
            .addCase(fetchMemeberBalacnce.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default userCenterSlice;
export const userCenterActions = userCenterSlice.actions;
