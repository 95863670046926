// 表单公用间距
export const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
export const tailFormItemLayout = {
    wrapperCol: { span: 24 },
};

// 新手导引Cookie保存时间长度
export const LEARN_TIME = 9999999999;

// 退出需要清除Cookie的key值
// 不需要清空的Cookie的key值 ["learnStep"]
export const CLEAR_COOKIE_KEY = [
    "personMessage",
    "systemMessage",
    "emailTime",
    "phoneTime",
    "dateTime",
    "ALBtype",
    "isAlbSecond",
    "isAlbThird",
    "isThird",
    "isWCThird",
    "withdrawLastTime",
];

// inbox api constants
export const SYSMSG_TYPE_ID_ENUM = {ALL: '0', BANKING: '7', PRODUCTS: '8', PROMOTIONS: '9', GENERAL: '10'};
export const INBOXMSG_TYPE_ID_ENUM = {TRANSACTION: '2', PERSONAL: '1', DISCOUNT: '3' };
export const INBOXMSG_TYPE_OPTION_ID_ENUM = {ALL:'0', DEPOSIT:'3',  TRANSFER:'4',REST:'4' , WITHDRAWAL:'5', BONUS:'6'}; // MUST be string, depends on getPersonData's design/behavior


export const isWindowOpenGame = ["IMOPT", "SGW", "NLE","SLS", "CML", "KPK"]; //不支持iframe的游戏放进去
export const isCookieNoOpenGame = ["MGSQF", "BGG", "AGL"]; //不支持第三方cookie
export const requireUniqueProviders = ['TGP', 'TG', 'JKR']; // call Launch api時需加上後綴，以成為Unique providerCode
export const SportsbookGameCatCode = 'Sportsbook';
export const ESportsGameCatCode = 'ESports';
export const InstantGamesGameCatCode = 'InstantGames';
export const LiveCasinoGameCatCode = 'LiveCasino';
export const P2PGameCatCode = 'P2P';
export const SlotGameCatCode = 'Slot';
export const KenoLotteryGameCatCode = 'KenoLottery';

export const enumWalletProductGroupId = {
    TOTAL: { ID: 0, piwikName: "Total" },
    MAIN: { ID: 1, piwikName: "Main" },
    SB: { ID: 2, piwikName: "Sports/Esports" }, // 体育 / 电竞
    LD: { ID: 4, piwikName: "Live Dealer" }, // 真人
    P2P: { ID: 24, piwikName: "P2P/InstantGames/Fishing" }, // 棋牌 / 小游戏
    SLOT: { ID: 32, piwikName: "Slots" }, // 游戏
    KENO: { ID: 64, piwikName: "Lottery" }, // 彩票
};

const MEMBER_NOTIFICATION_CATEGORY_5 = '5';
const MEMBER_NOTIFICATION_CATEGORY_6 = '6';
const MEMBER_NOTIFICATION_CATEGORY_7 = '7';
const MEMBER_NOTIFICATION_CATEGORY_8 = '8';
const MEMBER_NOTIFICATION_CATEGORY_999 = '999';

export const enumBonusStatusPMA = Object.assign(Object.create({
    displayStatusText: function(cateId, status, isPreBonus = false) {
        if (MEMBER_NOTIFICATION_CATEGORY_5 == cateId || MEMBER_NOTIFICATION_CATEGORY_7 == cateId || MEMBER_NOTIFICATION_CATEGORY_8 == cateId) {
           return (
               <div
                   style={{
                       color: "#34C759",
                       display: "flex",
                       alignItems: "center",
                   }}
               >
                   <span>
                       {isPreBonus && (
                           <img
                               style={{
                                   marginRight: "5px",
                                   display: "flex",
                                   justifyContent: "center",
                                   alignItems: "center",
                               }}
                               src="/vn/img/1wallet/00/green-icon.svg"
                               alt="succesIcon"
                           />
                       )}
                   </span>
                   Đã hoàn thành
               </div>
           );
        } else if (MEMBER_NOTIFICATION_CATEGORY_999 == cateId) {
            return <div  style={{color:'#EB2121', display:"flex", alignItems:'center'}}><span>{isPreBonus && <img style={{marginRight:"5px", display:"flex", justifyContent:"center", alignItems:"center"}} src="/vn/img/1wallet/00/red-icon.svg" alt="succesIcon"/>}</span>Đã hủy</div>
        } else if (MEMBER_NOTIFICATION_CATEGORY_6 == cateId) {
            return <div  style={{color:'#EB2121', display:"flex", alignItems:'center'}}><span>{isPreBonus && <img style={{marginRight:"5px", display:"flex", justifyContent:"center", alignItems:"center"}}  src="/vn/img/1wallet/00/red-icon.svg" alt="succesIcon"/>}</span>Đã hết hạn</div>
        }
    }
}), {
    AllStatus:{ID:0},
    Pending:{ID:1},
    Cancelled:{ID:2},
    InProgress:{ID:3},
    Processing:{ID:4},
    ForcedToServed:{ID:5},
    Complete:{ID:6},
    ClickToClaim:{ID:7},
    Expired:{ID:8},
    RequiredCheck:{ID:9},
});

export const enumVisibility = {OFF:'off', PUBLIC:'public', MEMBER:'member'};

export const enumPromoType = {
    MANUAL_PRE_BONUS:'Manual Pre-Bonus',
    MANUAL_ITEM:'Manual Item',
    RELOAD_BONUS:'Bonus',
    MANUAL2024:'Manual',
};

export const enumBonusStatus = {
    AllStatus:{ID:0},
    Pending:{ID:1},
    Cancelled:{ID:2},
    InProgress:{ID:3},
    Processing:{ID:4},
    ForcedToServed:{ID:5},
    Complete:{ID:6},
    ClickToClaim:{ID:7},
    Expired:{ID:8},
    RequiredCheck:{ID:9},
};

const enumPartnerBonusType = {
    PRE: 'PRE',
    POST: 'POST',
}

export const enumBonusRuleType = {
    PRE: 'PRE',
    POST: 'POST',
}


//{"bonusId": 175843, "playerBonusId": 2000001045, "bonusTitle": "Core Pre Bonus Normal Bonus SLOT 12", "creditAccountId": 124, "creditAccount": "游戏", "bonusGiven": "11.10", "bonusStatus": "已过期", "bonusStatusId": 11, "bonusStatusIdDescription": "Served", "appliedDate": "2023-11-07T16:19:40.778", "expriyDate": "2023-11-17T16:19:40.778", "isClaimable": false, "bonusCategory": 3, "bonusType": "锁定彩金", "percentage": 0, "progressText": "0.00 %", "bonusReleaseType": "TO:", "bonusReleaseTypeValue": "0/122.1", "partnerBonusType": "PRE", "statusTipsMessage": "" },
export const isPostPartnerBonusType = item => typeof item?.bonusRuleType==='string' && item.bonusRuleType.toUpperCase()===enumPartnerBonusType.POST;
export const isPrePartnerBonusType = item => typeof item?.bonusRuleType==='string' && item.bonusRuleType.toUpperCase()===enumPartnerBonusType.PRE;

const FishingGames = ["JIF", "JKR", "SPG", "SWF"]
export const WALLET_BALANCE_MAPPING = {
    "KenoLottery": "KENO",
    "P2P": "P2P",
    "Slot": "SLOT",
    "LiveCasino": "LD",
    "InstantGames": "P2P",
    "ESports": "SB",
    "Sportsbook": "SB",
};

const getWalletType = (cateCode, provider) => {
    // Check if cateCode is 'Slot' and provider is in FishingGames
    if (cateCode === "Slot" && FishingGames.includes(provider)) {
        return "P2P";
    }
    return WALLET_BALANCE_MAPPING[cateCode] || null;
};

export const checkInsufficientBalance = (cateCode, balanceInfo, provider) => {
    let walletType = getWalletType(cateCode, provider);
    if (!walletType) {
        // Show modal if wallet type is not found
        return false;
    }

    let walletTypeDetail = balanceInfo?.balances?.find(
        (item) => item.walletProductGroupCode === walletType
    );

    if (!walletTypeDetail || walletTypeDetail.balance < 30) {
        // Show modal if balance is insufficient
        return false;
    }

    return true; // Balance is sufficient
};

export const enumCampaignEntryPeriodDisplayStatus = {
    REQUIRED_LOGIN: 'REQUIRED_LOGIN',
    READY_TO_ENROLL: 'READY_TO_ENROLL',
    PENDING_RESULT: 'PENDING_RESULT',
    START_TO_CLAIM: 'START_TO_CLAIM',
    ALREADY_APPLIED: 'ALREADY_APPLIED',
    ALREADY_CLAIMED: 'ALREADY_CLAIMED',
}
