import React, { Component, useEffect, useRef, useState } from "react";
import {
    Row,
    Col,
    Modal,
    message,
    Radio,
    Input,
    Button,
    Icon,
    Spin,
    Select,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import { piwikBonus, sendPiwikUrl } from "../actions/piwikUtil";
import "react-toastify/dist/ReactToastify.css";
import { userCenterActions } from "../store/userCenterSlice";
import { promotionActions } from "../store/promotionSlice";
import { claimBonus } from "../data/wallet";
import { showErrorToast, showSuccessToast } from "../actions/helper";

const promotionErrorConfigModule = {
    PromotionErrorKeepPreviousStatus: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>
                    Yêu cầu đăng ký khuyến mãi đã được tiếp nhận.
                </h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Đăng ký của bạn đang được xem xét, trạng thái sẽ được cập
                    nhật sớm.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            sendPiwikUrl(
                "promotion_application_in_process_popup",
                "Promotion Application In Process Popup"
            );
            handleClose();
        },
        showSecondary: false, // Only one button
    },

    PromotionErrorExpired: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>
                    Đăng ký không thành công.
                </h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Khuyến mãi đã hết hạn, vui lòng chọn khuyến mãi khác.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            sendPiwikUrl("promotion_expired_popup", "Promotion Expired Popup");
            handleClose();
        },
        showSecondary: false, // Only one button
    },

    PromotionErrorParticipateOnce: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>
                    Yêu cầu đăng ký khuyến mãi đã được tiếp nhận.
                </h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Khuyến mãi đã được đăng ký, trạng thái sẽ được cập nhật sớm.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            sendPiwikUrl(
                "promotion_application_in_process_popup",
                "Promotion Application In Process Popup"
            );
            handleClose();
        },
        showSecondary: false, // Only one button
    },

    PromotionErrorHitMaxApplicant: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>Đã đạt giới hạn đăng ký</h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Đã đạt đủ giới hạn đăng ký, vui lòng chọn khuyến mãi khác.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            sendPiwikUrl(
                "promotion_application_full_popup",
                "Promotion Application Full Popup"
            );
            handleClose();
        },
        showSecondary: false, // Only one button
    },

    PromotionReloadBonusErrorOngoingBonus: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>
                    Đăng ký không thành công
                </h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Bạn vẫn còn khuyến mãi đang diễn ra nhưng chưa đáp ứng yêu
                    cầu Doanh thu. Vui lòng hoàn thành yêu cầu Doanh thu trước
                    khi đăng ký lại.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            sendPiwikUrl(
                "promotion_application_in_process_popup",
                "Promotion Application In Process Popup"
            );
            handleClose();
        },
        showSecondary: false, // Only one button
    },

    PromotionReloadBonusErrorExpired: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>
                    Đăng ký không thành công
                </h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Khuyến mãi đã hết hạn, vui lòng chọn khuyến mãi khác.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            sendPiwikUrl("promotion_expired_popup", "Promotion Expired Popup");
            handleClose();
        },
        showSecondary: false, // Only one button
    },

    PromotionReloadBonusErrorHitMaxApplicants: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>Đã đạt giới hạn đăng ký</h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Đã đạt đủ giới hạn đăng ký, vui lòng chọn khuyến mãi khác.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            sendPiwikUrl(
                "promotion_application_rebate_not_enough_popup",
                "Promotion Application Rebate Not Enough Popup"
            );
            handleClose();
        },
        showSecondary: false, // Only one button
    },

    PromotionReloadBonusErrorAlreadySentOrHitMaxAppliedPerMember: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>
                    Đăng ký không thành công
                </h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Mẫu đăng ký đã được gửi, hoặc số lượng đăng ký đã đạt mức
                    tối đa.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            sendPiwikUrl(
                "promotion_sent_or_personal_max_popup",
                "Promotion Sent or Hit Personal Max"
            );
            handleClose();
        },
        showSecondary: false, // Only one button
    },

    PromotionReloadBonusErrorInsufficientBalance: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>Không Đủ Số Dư</h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Xin lỗi, hiện số dư của bạn không đủ để tham gia khuyến mãi
                    này, vui lòng "Gửi Tiền" và tiếp tục tham gia.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            handleClose();
        },
        showSecondary: true, // Two buttons
        secondaryLabel: "Hủy",
    },
    InsufficientBalanceErrorForReloadBonusForm: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>Cảnh báo số dư</h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Xin lỗi, hiện số dư của bạn không đủ để tham gia khuyến mãi
                    này, vui lòng "Gửi Tiền" và tiếp tục tham gia.
                </p>
            </div>
        ),
        buttonLabel: "Gửi Tiền",
        onButtonClick: ({ handleClose, dispatch }, props) => {
            console.log("InsufficientBalanceErrorForReloadBonusForm'!", props);
            handleClose();
            dispatch(promotionActions.setOWSelectedBonus(props));
            sessionStorage.setItem(
                "promoDeposit",
                JSON.stringify({
                    bonusId: props.bonusId ?? "",
                    bonusRuleGroupId: props?.bonusData?.groupID || 0,
                    bonusProduct: props?.bonusProduct ?? "",
                    bonusMinAmount: props?.bonusMinAmount ?? "",
                    bonusTitle: props?.promoTitle ?? "",
                    depositingWallet: props?.bonusData?.account ?? "",
                })
            );
            global.showDialog({
                key: 'wallet:{"type": "deposit"}',
            });
        },
        secondaryLabel: "Hủy",
        showSecondary: true, // Only one button
        onSecondaryButtonClick: ({ handleClose, router }) => {
            handleClose(); // Close the modal
            // router.push("/"); // Navigate to the desired route
        },
        modalWidth: 440,
    },
    ReloadBonusAlreadySentOrHitPerMax: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>
                    Đăng ký không thành công
                </h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Mẫu đăng ký đã được gửi, hoặc số lượng đăng ký đã đạt mức
                    tối đa.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            sendPiwikUrl(
                "promotion_sent_or_personal_max_popup",
                "Promotion Sent or Hit Personal Max"
            );
            handleClose();
        },
        showSecondary: false, // Only one button
        modalWidth: 440,
    },
    ReloadBonusExpiry: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>
                    Đăng ký không thành công
                </h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Khuyến mãi đã hết hạn, vui lòng chọn khuyến mãi khác.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            sendPiwikUrl("promotion_expired_popup", "Promotion Expired Popup");
            handleClose();
        },
        showSecondary: false, // Only one button
        modalWidth: 440,
    },
    ReloadBonusReachMax: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>Đã đạt giới hạn đăng ký</h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Đã đạt đủ giới hạn đăng ký , vui lòng chọn khuyến mãi khác.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            sendPiwikUrl(
                "promotion_application_full_popup",
                "Promotion Application Full Popup"
            );
            handleClose();
        },
        showSecondary: false, // Only one button
        modalWidth: 440,
    },
    ReloadBonusInProgress: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>
                    Đăng ký không thành công
                </h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Bạn vẫn còn khuyến mãi đang diễn ra nhưng chưa đáp ứng yêu
                    cầu Doanh thu. Vui lòng hoàn thành yêu cầu Doanh thu trước
                    khi đăng ký lại.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            sendPiwikUrl(
                "promotion_application_rebate_not_enough_popup",
                "Promotion Application Rebate Not Enough Popup"
            );
            handleClose();
        },
        showSecondary: false, // Only one button
        modalWidth: 440,
    },
    InsufficientBalanceError: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>Cảnh báo số dư</h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Số dư hiện tại ít hơn 30 đ. Nạp tiền ngay để không cuộc chơi
                    không bị gián đoạn. 
                </p>
            </div>
        ),
        buttonLabel: "Nạp tiền ngay",
        onButtonClick: ({ handleClose }) => {
            handleClose();
            global.showDialog({
                key: 'wallet:{"type": "deposit"}',
            });
        },
        secondaryLabel: "Bỏ qua",
        showSecondary: true, // Only one button
        onSecondaryButtonClick: ({ handleClose, router }) => {
            handleClose(); // Close the modal
            // router.push("/"); // Navigate to the desired route
        },
        modalWidth: 440,
    },
    AssignedClaimHitMaxError: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>Đã đạt giới hạn đăng ký</h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Đã đạt đủ giới hạn đăng ký , vui lòng chọn khuyến mãi khác.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            handleClose();
        },
        secondaryLabel: "Bỏ qua",
        showSecondary: false, // Only one button
        modalWidth: 440,
    },
    AssignedClaimAlreadyRecieved: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>
                    Khuyến mãi đã được nhận.
                </h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Khuyến mãi đã được nhận, trạng thái sẽ được cập nhật sớm.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            sendPiwikUrl("promotion_applied_popup", "Promotion Applied Popup");
            handleClose();
        },
        secondaryLabel: "Bỏ qua",
        showSecondary: false, // Only one button
        modalWidth: 440,
    },
    AssignedClaimExpiryErrorAfterSubmitInWaitingForm: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>
                    Đăng ký không thành công.
                </h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Khuyến mãi đã hết hạn, vui lòng chọn khuyến mãi khác.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            handleClose();
        },
        showSecondary: false, // Only one button
        modalWidth: 440,
    },
    AssignedClaimExpiryError: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>
                    Đăng ký không thành công.
                </h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Khuyến mãi đã hết hạn, vui lòng chọn khuyến mãi khác.
                </p>
            </div>
        ),
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            handleClose();
        },
        showSecondary: false, // Only one button
        modalWidth: 440,
    },
    SignUpConfrimedToCancelModal: {
        content: (callbacks, props) => {
            console.log("SignUpConfrimedToCancelModal", props, callbacks);
            return (
                <div
                    style={{
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            paddingTop: "30px",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <h2>Xác nhận hủy khuyến mãi</h2>
                        </div>
                        <p
                            style={{
                                textAlign: "center",
                            }}
                        >
                            Khuyến mãi này chỉ có thể được đăng ký một lần. Nếu
                            hủy, bạn sẽ không thể đăng ký lại.
                        </p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "20px",
                            alignItems: "center",
                            width: "100%",
                            gap: "18px",
                        }}
                    >
                        <Button
                            style={{
                                border: "1px solid #00A6FF",
                                backgroundColor: "#FFFFFF",
                                color: "#00A6FF",
                            }}
                            onClick={() => {
                                callbacks.handleClose();
                            }}
                        >
                            Quay lại
                        </Button>
                        <Button
                            style={{
                                backgroundColor: "#00A6FF",
                                color: "#FFFFFF",
                            }}
                            onClick={async () => {
                                let rsp = await callbacks.callback();
                                console.log("rsp", rsp);
                            }}
                        >
                            Xác nhận
                        </Button>
                    </div>
                </div>
            );
        },
        showPrimary: false,
        modalWidth: 440,
    },
    ClaimableClickModal: {
        content: ({handleClose}, props) => {
            const[modalProps, setModalProps] = useState({});
            const userCenter = useSelector((state) => state.userCenter);
            const dispatch = useDispatch();
            useEffect(() => {
                console.log("ClaimableClickModal", props);
                console.log("userCenter",userCenter)
            },[])
            

            return (
            <div
            style={{
                padding: "40px 20px 20px 20px"
            }}
            >
                                    <span
                        onClick={() => {
                            handleClose();
                        }}
                        style={{
                            position: "absolute",
                            top: "15px",
                            right: "15px",
                            zIndex: "10",
                            cursor: "pointer",
                        }}
                    >
                        <img
                            src="/vn/img/1wallet/00/ModalCloseIcon.svg"
                            alt="Close Icon"
                        />
                    </span>
                <h3 style={{textAlign: "center"}}>Nhận Thưởng thành công</h3>
                <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "24px",
                    marginBottom: "24px"
                }}
                >
                    <img src="/vn/img/1wallet/00/modalSuccessIcon.svg" alt="successIcon"></img>
                </div>
                <p style={{textAlign: "center", marginBottom: "32px", color: "#666666"}}>Để rút số tiền thưởng đã nhận, vui lòng hoàn thành yêu cầu doanh thu trong khoảng thời gian quy định, nếu không, tiền thưởng có thể bị thu hồi.</p>

                <div>
                    <div>
                        {userCenter?.toggleOnOff && 
                        <Button
                        style={{
                            backgroundColor: "#00A6FF",
                            color: "#FFFFFF",
                            height: "50px",
                        }}
                        onClick={async() => {
                            piwikBonus.run20()
                            let r = await claimBonus(props?.bonus?.playerBonusId)
                            if(claimBonus.isClaimed(r)) {
                                props?.callbackAfterSuccess();
                                dispatch(userCenterActions.setShowLockBalanceModal(true));
                                handleClose();
                            }else {
                                showErrorToast("Hủy bỏ không thành công")
                            }
                        }}
                        >
                        Kiểm tra số dư bị khóa
                        </Button>}
                        <Button
                        style={{
                            backgroundColor: userCenter?.toggleOnOff ? "#FFFFFF" : "#00A6FF",
                            marginTop: "12px",
                            color: userCenter?.toggleOnOff ? "#00A6FF" : "#FFFFFF",
                            height: "50px",
                        }}
                        onClick={() => {
                            handleClose();
                        }}
                        >
                        Đã Hiểu
                        </Button>
                    </div>
                </div>
            </div>)
        },
        buttonLabel: "Kiểm tra số dư bị khóa",
        onButtonClick: ({ handleClose }) => {
            handleClose();
            global.showDialog({
                key: 'wallet:{"type": "deposit"}',
            });
        },
        secondaryLabel: "Đã Hiểu",
        showPrimary: false,
        showSecondary: false,
        onSecondaryButtonClick: ({ handleClose, router }) => {
            handleClose(); // Close the modal
            // router.push("/"); // Navigate to the desired route
        },
        modalWidth: 440,
    },
    ClaimableCliclToCacncelCP30013: {
        content: (
            <div style={{ paddingTop: "44px" }}>
                <h3 style={{ textAlign: "center" }}>Hủy bỏ không thành công</h3>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "32px",
                        color: "#666666",
                    }}
                >
                    Để rút số tiền thưởng đã nhận, vui lòng hoàn thành yêu cầu doanh thu trong khoảng thời gian quy định, nếu không, tiền thưởng có thể bị thu hồi.
                </p>
            </div>
        ),
        showPrimary: false,
        buttonLabel: "Đã Hiểu",
        onButtonClick: ({ handleClose }) => {
            handleClose();
        },
        secondaryLabel: "Đã Hiểu",
        showSecondary: false, // Only one button
        onSecondaryButtonClick: ({ handleClose, router }) => {
            handleClose(); // Close the modal
            // router.push("/"); // Navigate to the desired route
        },
        modalWidth: 440,
    },
};

export default promotionErrorConfigModule;
